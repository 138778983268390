var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title)?_c('div',{class:_vm.$style.title,style:({
      maxWidth: _vm.titleMaxWidth,
      textAlign: _vm.titleAlign,
    })},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subTitle)?_c('div',{class:_vm.$style.subTitle},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]):_vm._e(),_c('div',{class:[_vm.$style.flexbox, _vm.$style.flexboxCenter]},[_vm._t("default"),(_vm.meaning.sideTexts)?_c('div',{class:_vm.$style._mmd1mw},[(_vm.bar)?_vm._l((_vm.meaning.sideTexts),function(text,i){return _c('div',{key:i,style:({
          marginBottom: '10px',
          fontSize: '18px'
        })},[_c('div',{style:({color: text.color})},[_vm._v(" "+_vm._s(text.title)+" ")]),_c('div',[_vm._v(" "+_vm._s(text.value)+" ")])])}):_vm._l((_vm.meaning.sideTexts),function(text,i){return _c('div',{key:i,style:({
          marginBottom: '10px',
          fontSize: '18px'
        })},[_c('div',{style:({
            color: text.color
          })},[_vm._v(" "+_vm._s(text.title)+" ")]),(_vm.meaning.data.series[i].data)?_c('div',[_vm._v(" "+_vm._s(_vm.meaning.data.series[i].data[1])+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.meaning.data.series[i])+" ")])])})],2):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }