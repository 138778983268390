<template>
  <div>
    <div
      v-if="title"
      :class="$style.title"
      :style="{
        maxWidth: titleMaxWidth,
        textAlign: titleAlign,
      }"
    >
      {{ title }}
    </div>
    <div
      v-if="subTitle"
      :class="$style.subTitle"
    >
      {{ subTitle }}
    </div>
    <div :class="[$style.flexbox, $style.flexboxCenter]">
      <slot class="abcd" name="default" />
      <div
      v-if="meaning.sideTexts"
      :class="$style._mmd1mw"

      >
      <template v-if="bar">
        <div
          v-for="(text, i) in meaning.sideTexts"
          :key="i"
          :style="{
            marginBottom: '10px',
            fontSize: '18px'
          }"
        >
          <div :style="{color: text.color}">
            {{text.title}}
          </div>
          <div>
            {{text.value}}
          </div>
        </div>
      </template>


        <div
          v-else
          v-for="(text, i) in meaning.sideTexts"
          :key="i"
          :style="{
            marginBottom: '10px',
            fontSize: '18px'
          }"
        >
          <div :style="{
              color: text.color
            }">
            {{text.title}}
          </div>
          <div v-if="meaning.data.series[i].data">
            {{meaning.data.series[i].data[1]}}
          </div>
          <div v-else>
            {{meaning.data.series[i]}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartWrapper',
  props: {
    bar: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '',
    },
    titleMaxWidth: {
      type: String,
      default: '60%',
    },
    titleAlign: {
      type: String,
      default: 'center',
    },
    subTitle: {
      type: String,
      default: '',
    },
    meaning : {
      type: Object,
    }
  },
};
</script>

<style module lang="scss">
.title {
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  color: var(--v-chartsTitle-base);
}

.subTitle {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: var(--v-chartsTitle-base);
  margin-bottom: 10px;
}
.flexbox {
  display: flex;
}
.flexboxCenter {
  align-items: center;
  flex: 1 1 auto;
  gap: 15px;
  @media (max-width:1660px){
    flex-direction: column;
  }
}
._mmd1mw {
  @media (min-width:1660px){
    max-width: 50%
  }
}
</style>
