var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adresses-jobsTypes"},[_c('div',{staticClass:"adresses-jobsTypes__head"},[_c('div',{staticClass:"adresses-jobsTypes__title"},[_vm._v(_vm._s(_vm.tableData.title))]),_c('JobsTypesFilters',{attrs:{"select":_vm.select},on:{"changeTypePlan":_vm.searchUpdate}})],1),_c('vue-custom-scrollbar',{staticClass:"adresses-jobsTypes__body",attrs:{"settings":_vm.scrollbarSettings},on:{"ps-y-reach-end":_vm.scrollCallback}},[_c('v-data-table',{staticClass:"custom-table transparent",attrs:{"headers":_vm.tableData.headers,"fixed-header":true,"options":_vm.options,"items":_vm.tableData.sortedHouses,"item-key":"id","search":_vm.search,"hide-default-footer":true,"calculate-widths":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.tableData.slots==='jobsTypes')?{key:"item.adress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"120px"}},[_vm._v(_vm._s(item.adress))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.roof",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#009FD1"}},[_vm._v(_vm._s(item.roof))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.warm",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#009FD1"}},[_vm._v(_vm._s(item.warm))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.electricity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#009FD1"}},[_vm._v(_vm._s(item.electricity))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.basement",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#009FD1"}},[_vm._v(_vm._s(item.basement))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.foundation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#009FD1"}},[_vm._v(_vm._s(item.foundation))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.facade",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#1EB94B"}},[_vm._v(_vm._s(item.facade))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.gas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#1EB94B"}},[_vm._v(_vm._s(item.gas))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.elevator",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#1EB94B"}},[_vm._v(_vm._s(item.elevator))])]}}:null,(_vm.tableData.slots==='jobsTypes')?{key:"item.water",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-jobsTypes__slot",staticStyle:{"color":"#FF0D0D"}},[_vm._v(_vm._s(item.water))])]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }