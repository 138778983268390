<template>
  <v-row align="stretch">
    <v-col
      v-for="(card, cKey) in cards"
      :key="cKey"
      cols="12"
      md="6"
      lg="3"
      style="height: 100%"
    >
      <v-card
        v-if="!loading"
        :dark="blackTheme"
        elevation="2"
        width="100%"
        height="100%"
        class="d-flex flex-column pa-2"
      >
        <v-col
          cols="12"
          class="d-flex outages__text text-h6"
          style="border-bottom: 1px solid;"
        >
          <span class="font-weight-regular">
            {{ card.title }}
          </span>
          <v-spacer />
          <div class="font-weight-bold">
            {{ card.value }}
          </div>
        </v-col>
        <v-col
          cols="12"
          class="text-left d-flex pt-2"
          style="border-bottom: 1px solid; border-bottom-left-radius: initial; border-bottom-right-radius: initial;"
          :style="cKey == 1 ? 'line-height: 1' : 'line-height: 2.67'"
          v-for="(child, chKey) in card.childrens"
          :key="chKey"
        >
          <div class="font-weight-bold">
            {{ child.title }}
          </div>
          <v-spacer />
          <div class="font-weight-bold">
            {{ child.value }}
          </div>
        </v-col>
      </v-card>
      <v-skeleton-loader v-else type="list-item-two-line" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['loading', 'blackTheme'],
  data() {
    return {
      data: [],
      cards: [
        {
          title: 'Количество объектов',
          value: '9 шт.',
          childrens: [
            {
              title: 'Социальные объекты',
              value: 0,
            },
            {
              title: 'Коммунальное хозяйство',
              value: 9,
            },
            {
              title: 'Дороги',
              value: 0,
            },
          ]
        },
        {
          title: 'Общий бюджет',
          value: '2.21 млрд. руб.',
          childrens: [
            {
              title: 'Федеральный бюджет',
              value: '2.14 млрд. руб.',
            },
            {
              title: 'Республиканский бюджет',
              value: '46.64 млн. руб.',
            },
            {
              title: 'Муниципальный бюджет',
              value: '28.29 млн. руб.',
            },
            {
              title: 'Внебюджет',
              value: 0,
            },
            {
              title: 'Концессия',
              value: 0,
            },
          ]
        },
        {
          title: 'Фактически выполнено',
          value: '842.81 млн. руб.',
          childrens: [
            {
              title: 'Социальные объекты',
              value: 0,
            },
            {
              title: 'Коммунальное хозяйство',
              value: '842.81 млн. руб.',
            },
            {
              title: 'Дороги',
              value: 0,
            },
          ]
        },
        {
          title: 'Фактически оплачено',
          value: '842.81 млн. руб.',
          childrens: [
            {
              title: 'Социальные объекты',
              value: 0,
            },
            {
              title: 'Коммунальное хозяйство',
              value: '842.81 млн. руб.',
            },
            {
              title: 'Дороги',
              value: 0,
            },
          ]
        },
      ]
    }
  },
  methods: {
  },
  mounted() {

  },
  watch: {

  }
}
</script>

<style>

</style>
