<template>
  <v-container class="col-12 outages pr-xl-6" :class="{'outages--black': blackTheme}">
    <v-row class="justify-space-between align-center">
      <v-col cols="12" sm="4" md="5">
        <h2 class="eye__text text-uppercase">Капитальное строительство</h2>
      </v-col>
      <CapitalConstructionFilters
        @filter-updated="updateFilter"
      />
    </v-row>

    <template>
      <CapitalConstructionCards
        :loading="loading"
        :blackTheme="blackTheme"
      />
    </template>
    <v-col cols="12" class="px-0 pt-5">
      <v-simple-table dense v-if="true">
        <thead>
          <tr>
            <th>Наименование программы</th>
            <th>Наименование муниципалитета</th>
            <th>ГРБС</th>
            <th>Тип ОКС</th>
            <th>Группа ОКС</th>
            <th>Наименование объекта</th>
            <th>Вид деятельности</th>
            <th>Лимит текущего года</th>
            <th>Дата заключения контракта</th>
            <th>Наименование подрядчика</th>
            <th>ФИО Руководителя</th>
            <th>Плановая дата окончания работ</th>
            <th>Дата фактического окончания работ</th>
            <th>Бюджет РФ</th>
            <th>Бюджет РБ</th>
            <th>Бюджет МО</th>
            <th>Внебюждет</th>
            <th>Концессия</th>
            <th>Сумма контракта, руб.</th>
            <th>Фактически выполнено, руб.</th>
            <th>Фактически оплачено, руб.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tdata, tdataKey) in tableData" :key="tdataKey">
            <td v-for="(td, tdKey) in tdata" :key="tdKey">{{ td }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-skeleton-loader type="table" style="width: 100%" v-else></v-skeleton-loader>
    </v-col>
  </v-container>
</template>

<script>
import CapitalConstructionFilters from '@/components/CapitalConstruction/Filters.vue';
import CapitalConstructionCards from '@/components/CapitalConstruction/Cards.vue';

export default {
  name: 'CapitalConstruction',
  metaInfo: {
    title: 'Капитальное строительство'
  },
  components: {
    CapitalConstructionFilters,
    CapitalConstructionCards,
  },
  data: () => ({
    blackTheme: false,
    dataLoaded: false,
    loading: false,
    defaultTableData: [],
    tableData: [
      ['Чистая вода',
      'МР Альшеевский район РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Строительство системы очистки воды в сельском поселении Раевский сельсовет Альшеевского района Республики Башкортостан',
      'Строительство',
      '65 927 248,36',
      '16.06.2022',
      'ООО "ГЛАВСПЕЦСТРОЙ"',
      'Исачкин Семён Александрович',
      '30.06.2023',
      '—',
      '176 541 266,12',
      '3 642 048,97',
      '2 743 908,33',
      '—',
      '—',
      '182 927 223,42',
      '116 999 975,06',
      '116 999 975,06',
      ],['Чистая вода',
      'г.Уфа РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Строительство водопровода в поселок Цветы Башкирии в г. Уфе',
      'Строительство',
      '111 883 135,57',
      '17.12.2021',
      'АО "Башкиравтодор"',
      'Юланов Ильдар Гизитдинович',
      '30.06.2023',
      '—',
      '315 949 141,42',
      '6 448 091,58',
      '0,00',
      '—',
      '—',
      '322 397 233,00',
      '210 513 339,78',
      '210 513 339,78',
      ],['Чистая вода',
      'ГП город Бирск РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Строительство станции очистки питьевой воды, водозаборных скважин, насосной станции 2-го подъема, резервуаров чистой воды и магистральных водоводов в городском поселении г. Бирск МР Бирский район Республики Башкортостан',
      'Строительство',
      '295 046 236,18',
      '27.12.2021',
      'ООО "Билд"',
      'Хасанов Рустам Рафаилович',
      '25.11.2023',
      '—',
      '776 336 441,69',
      '15 843 582,11',
      '12 063 689,48',
      '—',
      '—',
      '804 243 713,28',
      '509 197 477,10',
      '509 197 477,10',
      ],['Чистая вода',
      'МР Краснокамский район РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Строительство водопровода для водоснабжения с. Новонагаево Краснокамского района РБ',
      'Строительство',
      '11 602 744,50',
      '16.03.2022',
      'ООО "Регион Уфа"',
      'Каримов Гали Саитгалиевич',
      '30.10.2023',
      '—',
      '29 306 692,38',
      '598 095,77',
      '455 403,05',
      '—',
      '—',
      '30 360 191,20',
      '18 757 446,70',
      '18 757 446,70',
      ],['Чистая вода',
      'МР Чишминский район РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Реконструкция водовода от Исаковского водозабора для водоснабжения р.п. Чишмы МР Чишминский район Республики Башкортостан',
      'Строительство',
      '99 042 080,22',
      '21.03.2023',
      'ООО "ПЛУТОС"',
      'Мухаметяров Артур Минибаевич',
      '30.11.2024',
      '—',
      '105 669 956,56',
      '2 156 529,74',
      '1 642 027,71',
      '—',
      '—',
      '109 468 514,01',
      '0,00',
      '0,00',
      ],['Чистая вода',
      'МР Уфимский район РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Водоснабжение д. Сергеевка муниципального района Уфимский район Республики Башкортостан',
      'Строительство',
      '42 915 779,71',
      '29.03.2022',
      'ООО "МОДУЛЬ-М"',
      'Кужагильдин Радик Раисович',
      '30.06.2023',
      '—',
      '64 463 167,38',
      '1 315 574,89',
      '1 001 706,73',
      '—',
      '—',
      '66 780 449,00',
      '23 864 669,29',
      '23 864 669,29',
      ],['Чистая вода',
      'МР Хайбуллинский район РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Водоснабжение с. Бурибай муниципального района Хайбуллинский район РБ. 2 очередь',
      'Строительство',
      '56 134 142,37',
      '31.03.2022',
      'АО "БАШКОММУНПРИБОР"',
      'Темников Антон Владимирович',
      '01.11.2023',
      '—',
      '141 380 013,60',
      '2 885 306,40',
      '2 196 933,73',
      '—',
      '—',
      '146 462 253,73',
      '56 134 142,37',
      '56 134 142,37',
      ],['Чистая вода',
      'МР Кугарчинский район РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Система водоснабжения с. Мраково Кугарчинского района',
      'Строительство',
      '201 678 193,57',
      '22.11.2022',
      'ООО "БИЛД"',
      'Хасанов Рустам Рафаилович',
      '30.11.2023',
      '—',
      '250 520 060,28',
      '5 112 654,28',
      '3 892 883,96',
      '—',
      '—',
      '259 525 598,52',
      '57 847 404,95',
      '57 847 404,95',
      ],['Чистая вода',
      'МР Туймазинский район РБ',
      'Минжилкомхоз РБ',
      'Сети водоснабжения',
      'Инж.сети',
      'Строительство межпоселкового водовода от мкр. Агиртамак г. Туймазы до с. Райманово и уличных сетей водопровода с. Райманово МР Туймазинский район РБ',
      'Строительство',
      '226 153 021,30',
      '06.12.2022',
      'АО "РВК ВОДОКАНАЛ"',
      'Озеров Андрей Михайлович',
      '30.10.2023',
      '—',
      '276 223 511,46',
      '5 637 214,52',
      '4 292 295,32',
      '—',
      '—',
      '286 153 021,30',
      '60 000 000,00',
      '60 000 000,00',]

    ]
  }),
  methods: {
    changeTheme() {
      this.blackTheme = !this.blackTheme;
      this.$emit('change-theme', this.blackTheme);
    },
    updateFilter(obj, value) {
      if (value && value !== 'all') {
        this.tableData = this.defaultTableData.filter(tdata => {
          return tdata.includes(value)
        })
      } else {
        this.tableData = this.defaultTableData
      }
    },
  },
  mounted() {
    this.changeTheme();
    this.defaultTableData = this.tableData;
  },
}
</script>
