var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"col-12"},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold text-uppercase",attrs:{"cols":"12"}},[_vm._v(" Список организаций ")])],1),_c('PageFilters',{ref:"filter",attrs:{"types":[ 'region', 'org' ]},on:{"changeFtr":_vm.changeFtr}}),_c('v-row',{staticClass:"col-12 board-row mx-auto"},[_c('v-slide-group',[_c('v-slide-item',[_c('HeatCard',{staticClass:"ma-3",staticStyle:{"width":"350px","height":"300px"}})],1),_c('v-slide-item',[_c('DashboardCard',{staticClass:"ma-3",staticStyle:{"width":"350px","height":"300px"}})],1),_c('v-slide-item',[_c('PaymentsCard',{staticClass:"ma-3",staticStyle:{"width":"350px","height":"300px"}})],1),_c('v-slide-item',[_c('OutagesCard',{staticClass:"ma-3",staticStyle:{"width":"350px","height":"300px"},attrs:{"values":_vm.dashboards.shutdowns}})],1),_c('v-slide-item',[_c('EfficiencyCard',{staticClass:"ma-3",staticStyle:{"width":"350px","height":"300px"},attrs:{"values":_vm.dashboards.energy}})],1),_c('v-slide-item',[_c('OrganizationsCard',{staticClass:"ma-3",staticStyle:{"width":"350px","height":"300px"},attrs:{"values":{ 'totalHouses': _vm.dashboards.energy.totalHouses, 'orgs': _vm.dashboards.heatOrgs }}})],1)],1)],1),_c('v-row',[_c('v-col',{ref:"orgList",style:('max-height: '+ _vm.heightForList +'px; overflow-y: auto;'),attrs:{"cols":"12"},on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[_vm._l((_vm.orgList),function(org,org_index){return _c('v-card',{key:org_index,staticClass:"mb-5"},[_c('v-card-title',[_c('h6',[_vm._v(_vm._s(org.shortName))]),_c('v-spacer'),_c('router-link',{staticClass:"primary--text text-caption text-underline link",attrs:{"to":{
              'name': 'OrgInfo',
              'query': {
                'guid': org.guid
              },
              'params': {
                'filter': _vm.filters
              }
            }}},[_vm._v(" Перейти к организации ")])],1),_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.orgData(org_index)),function(od,od_key){return _c('tr',{key:od_key},[_c('td',[_vm._v(_vm._s(od.title))]),(od.hasOwnProperty('type'))?_c('td',[(od.type == 'phone')?_c('a',{attrs:{"href":'tel:'+ od.value}},[_vm._v(_vm._s(od.value))]):(od.type == 'email')?_c('a',{attrs:{"href":'mailto:'+ od.value}},[_vm._v(_vm._s(od.value))]):(od.type == 'url')?_c('a',{attrs:{"href":od.value,"target":"_blank"}},[_vm._v(_vm._s(od.value))]):_vm._e()]):_c('td',[_vm._v(_vm._s(od.value ? od.value : 'Нет данных'))])])}),0)])],1)}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.resOrgPagination.scroll),expression:"resOrgPagination.scroll"}]},[_c('v-skeleton-loader',{attrs:{"type":"article"}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }