<template>
  <div>
    <div
      v-if="title"
      :class="$style.title"
      :style="{
        maxWidth: titleMaxWidth,
        textAlign: titleAlign,
      }"
    >
      {{ title }}
    </div>
    <div
      v-if="subTitle"
      :class="$style.subTitle"
    >
      {{ subTitle }}
    </div>
    <div :class="[$style.flexbox, $style.flexboxCenter]" :ref="'speedometer'">
      <vue-speedometer
        :maxSegmentLabels="1"
        :segments="3"
        :value="value"
        :segmentColors='meaning.colors'
        :maxValue="maxValue"
        :width="320"
        :height="250"
        :needleColor= 'meaning.needleColor'
        :textColor="meaning.textColor"
      />
    </div>
  </div>
</template>

<script>

import VueSpeedometer from "vue-speedometer";

export default {
  components: { VueSpeedometer },
  name: 'Speedometer',
  props: {
    themeMode: {
      type: String,
      default: 'light',
    },
    title: {
      type: String,
      default: '',
    },
    titleMaxWidth: {
      type: String,
      default: '60%',
    },
    titleAlign: {
      type: String,
      default: 'center',
    },
    subTitle: {
      type: String,
      default: '',
    },
    meaning: {
      type: Object,
      default: ()=>{
        return {
          colors: ["tomato", "gold", "limegreen"],
          value: 0,
          maxValue: 0,
          needleColor: '#e2e2e2',
          textColor: '#e2e2e2'
        }
      },
    },
    value: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 0
    },
  },
  mounted() {
    this.$refs['speedometer'].querySelectorAll('.label .segment-value').forEach((e, i)=>{
      e.removeAttribute('transform');
      if (i===0) {
        e.style.transform = 'translate(-110px, 23px)';
      } else {
        e.style.transform = 'translate(110px, 23px)';
      }
    })
  },
  watch: {
    maxValue() {
      this.$refs['speedometer'].querySelectorAll('.label .segment-value')[1].innerHTML = this.maxValue;
    }
  }
};
</script>

<style module>
.title {
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  color: var(--v-chartsTitle-base);
}

.subTitle {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: var(--v-chartsTitle-base);
  margin-bottom: 10px;
}
.flexbox {
  display: flex;
}
.flexboxCenter {
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}
</style>
