var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"500px","value":_vm.isOpen},on:{"input":_vm.handleClose}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.getTitle))]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.handleClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"disabled":_vm.pending},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-checkbox',{attrs:{"label":"Обследование проведено","value":_vm.item.isInspected},on:{"change":function (value) { return _vm.handleChangeCheckbox('isInspected', value); }}}),_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","transition":"scale-transition","disabled":!_vm.item.isInspected,"close-on-content-click":false,"nudge-right":40},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Дата обследования","disabled":!_vm.item.isInspected,"rules":_vm.rules.inspectDate,"value":_vm.convertDate(_vm.item.inspectDate)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.inspectDate),callback:function ($$v) {_vm.inspectDate=$$v},expression:"inspectDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.inspectDate = false}},model:{value:(_vm.item.inspectDate),callback:function ($$v) {_vm.$set(_vm.item, "inspectDate", $$v)},expression:"item.inspectDate"}})],1),_c('v-select',{attrs:{"item-text":"label","item-value":"value","label":"Результат обследования","items":_vm.isRepairRequired,"disabled":!_vm.item.isInspected},model:{value:(_vm.item.isRepairRequired),callback:function ($$v) {_vm.$set(_vm.item, "isRepairRequired", $$v)},expression:"item.isRepairRequired"}}),_c('v-textarea',{attrs:{"label":"Описание требуемого ремонта","disabled":!_vm.item.isInspected},model:{value:(_vm.item.repairDescription),callback:function ($$v) {_vm.$set(_vm.item, "repairDescription", $$v)},expression:"item.repairDescription"}}),_c('v-file-input',{attrs:{"show-size":"","label":"Акт обследования","disabled":!_vm.item.isInspected,"rules":_vm.rules.inspectAct,"value":_vm.item.inspectAct},on:{"change":function (file) { return _vm.handleChangeFile(file, 'inspect_act', 'inspectAct'); }}}),_c('v-checkbox',{attrs:{"label":"Проведено осмечивание","disabled":!_vm.item.isInspected},model:{value:(_vm.isEstimated),callback:function ($$v) {_vm.isEstimated=$$v},expression:"isEstimated"}}),_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","transition":"scale-transition","disabled":!_vm.isEstimated,"close-on-content-click":false,"nudge-right":40},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Дата осмечивания","disabled":!_vm.isEstimated,"rules":_vm.rules.estimateDate,"value":_vm.convertDate(_vm.item.estimateDate)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.estimateDate),callback:function ($$v) {_vm.estimateDate=$$v},expression:"estimateDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.estimateDate = false}},model:{value:(_vm.item.estimateDate),callback:function ($$v) {_vm.$set(_vm.item, "estimateDate", $$v)},expression:"item.estimateDate"}})],1),_c('v-text-field',{attrs:{"type":"number","label":"Сумма затрат по смете, руб.","required":_vm.item.isEstimated,"disabled":!_vm.isEstimated,"rules":_vm.rules.estimatedCost},model:{value:(_vm.item.estimatedCost),callback:function ($$v) {_vm.$set(_vm.item, "estimatedCost", $$v)},expression:"item.estimatedCost"}}),_c('v-file-input',{attrs:{"show-size":"","required":"","label":"Смета","rules":_vm.rules.estimate,"disabled":!_vm.isEstimated,"value":_vm.item.estimate},on:{"change":function (file) { return _vm.handleChangeFile(file, 'estimate', 'estimate'); }}}),_c('v-checkbox',{attrs:{"label":"Выполнен ремонт","disabled":!_vm.isEstimated},model:{value:(_vm.isRepaired),callback:function ($$v) {_vm.isRepaired=$$v},expression:"isRepaired"}}),_c('v-text-field',{attrs:{"type":"number","label":"Сумма затрат фактическая, руб.","required":_vm.item.isRepaired,"disabled":!_vm.isRepaired,"rules":_vm.rules.factualCost},model:{value:(_vm.item.factualCost),callback:function ($$v) {_vm.$set(_vm.item, "factualCost", $$v)},expression:"item.factualCost"}}),_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","transition":"scale-transition","disabled":!_vm.isRepaired,"close-on-content-click":false,"nudge-right":40},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Дата ремонта","rules":_vm.rules.repairDate,"disabled":!_vm.isRepaired,"value":_vm.convertDate(_vm.item.repairDate)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.repairDate),callback:function ($$v) {_vm.repairDate=$$v},expression:"repairDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.repairDate = false}},model:{value:(_vm.item.repairDate),callback:function ($$v) {_vm.$set(_vm.item, "repairDate", $$v)},expression:"item.repairDate"}})],1),_c('v-file-input',{attrs:{"show-size":"","label":"Акт КС-2","rules":_vm.rules.repairAct,"disabled":!_vm.isRepaired,"value":_vm.item.repairAct},on:{"change":function (file) { return _vm.handleChangeFile(file, 'repair_act', 'repairAct'); }}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"success","loading":_vm.pending},on:{"click":_vm.handleSaveItem}},[_vm._v(" "+_vm._s(_vm.editedId ? "Сохранить" : "Создать")+" ")]),_c('v-btn',{attrs:{"small":"","color":"error","disabled":_vm.pending},on:{"click":_vm.handleClose}},[_vm._v(" Закрыть ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }