export const DEFAULT_VALUES_VENTILATION_INPUT = {
  systemType: "",
  houseChimneysCount: undefined,                  // number
  houseChimneysLength: undefined,                 // float
  houseVentilationChannelsCount: undefined,       // number
  houseVentilationChannelsLength: undefined,      // float
  houseBuildYear: undefined,                      // number
  houseSquare: undefined,                         // float
  houseFloors: undefined,                         // number
  houseEntrances: undefined,                      // number
  houseFlats: undefined,                          // number
  house20cFormDate: "",
  house20cFormConclusion: "",
  house20cFormShutdownReason: "",
  houseGasShutdownDate: "",                       // datetime
  houseGasShutdownAffectedFlatsCount: undefined,  // number
  houseGasShutdownResumeDate: "",                 // datetime
  houseGasShutdownResumeAffectedFlatsCount: undefined,  // number
  houseDeteriorationPercentage: undefined,              // float
  checkupContractorName: "",
  checkupContractorContractNumber: "",
  checkupContractorContractDate: undefined, // dateTime
  checkupResult: undefined,
  costEstimatingContractorName: "",
  costEstimatingContractNumber: "",
  costEstimatingContractDate: undefined, // dateTime
  costEstimatingCost: undefined, // float
  repairsContractorName: "",
  repairsContractNumber: "",
  repairsContractDate: undefined, // dateTime
  repairsCost: undefined, // float
  repairsPeriod: undefined, // datetime
  ventilationRepairTypeId: undefined, // number
  ventilationRepairedSystemsCount: undefined, // number
  note: "",
};

export const CONDITION_DEFAULT_VALUES = {
  isInspected: false,
  inspectDate: "",
  isRepairRequired: false,
  repairDescription: "",
  inspectAct: null,
  isEstimated: false,
  estimateDate: "",
  estimatedCost: null,
  estimate: null,
  isRepaired: false,
  repairDate: "",
  factualCost: null,
  repairAct: null,
}
