<template>
  <ChartWrapperVent :title="title" :subTitle="subTitle" :themeMode="themeMode" :meaning="meaning" :titleMaxWidth="titleMaxWidth" :bar="bar">
    <apexchart
      height="210"
      :options="chartOptions"
      :series="meaning.data.series"
    />
  </ChartWrapperVent>
</template>

<script>
import ChartWrapperVent from '@/components/Ventilation/ChartWrapperVent';

export default {
  name: 'VentilationDiagram',
  components: {
    ChartWrapperVent,
  },
  props: {
    bar: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    titleMaxWidth: {
      type: String,
      default: '60%',
    },
    themeMode: {
      type: String,
      default: 'light',
    },
    meaning: {
      type: Object,
      default: () => {
        return {
          sideTexts: [],
          data: {
            series: [],
            labels: []
          },
          total: [],
          colors: [],
        }
      }
    },
  },
  computed: {
    chartOptions() {
      return {
          chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
        },
        colors: this.meaning.colors,
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            labels: {
              show: true,
            }
          },
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          total: {
            position: 'top',
            enabled: true,
          },
          formatter: (val, opts) => {
            let index = opts.dataPointIndex;
            let total = this.meaning.data.total;
            let newVal = null;
            if (val !== 0) {
              newVal = val / total[index] * 100
            } else {
              newVal = val;
            }
            // return newVal.toFixed(3) + '%'
            return Math.trunc(newVal) + '%'
          },
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: this.themeMode==='light' ? ['#111'] : ['#fff']
            }
          }
        }
      }
    }
  },
  watch: {
    themeMode() {
    }
  }
};
</script>
