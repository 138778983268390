<template>
  <div>
    <div class="text-h4 mb-7">Единый реестр МКД с газовым оборудованием</div>

    <v-row justify="space-between">
      <v-col cols="12" md="6" lg="5">
        <v-autocomplete
          dense
          filled
          rounded
          no-filter
          hide-details
          hide-no-data
          hide-selected
          return-object
          color="white"
          item-text="address"
          class="text-subtitle-2"
          placeholder="Адрес МКД"
          :disabled="loading"
          :items="addresses"
          :loading="addressLoading"
          :search-input.sync="search"
          v-model="address"
        />
      </v-col>

      <v-col
        v-show="isShowData"
        cols="auto"
      >
        <v-row>
          <v-col
            cols="auto"
            v-if="houseId !== (null&&undefined&&0)"
          >
            <v-btn v-if="addEntryPermission" @click="() => handleEditItem()">
              Добавить запись
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            v-if="!placeholderHouseText"
          >
            <v-btn @click="handleDownloadTable">
              Скачать таблицу
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <PlaceholderPage
      v-if="!isShowData"
      :text="placeholderText"
    />
    <PlaceholderPage
      v-if="houseId === (null&&undefined&&0) && isShowData"
      :text="placeholderHouseText"
    />

    <template v-if="isShowData && houseId !== (null&&undefined&&0)">
      <div :class="$style.widgets">
        <div
          v-for="(widget, index) in widgets"
          :key="index"
          class="d-flex flex-column align-baseline flex justify-space-between unit unit--stats"
        >
          <div class="unit__col">
            <div class="unit__title">{{ widget.title }}</div>
          </div>
          <div class="unit__col unit__col--right unit__col--100">
            <div class="unit__value text-h5 font-weight-semibold">{{ widget.value }}</div>
          </div>
        </div>
      </div>

      <VueCustomScrollbar
        :class="$style.wrapper"
        :settings="scrollbarSettings"
      >
        <VDataTable
          disable-sort
          fixed-header
          calculate-widths
          hide-default-footer
          class="custom-table transparent"
          loading-text="Загрузка..."
          item-key="id"
          :loading="loading"
          :headers="tableData.headers"
          :items="items"
          :items-per-page="-1"
        >
          <template v-slot:item.isInspected="{ item }">
            <v-checkbox
              disabled
              class="d-inline-block"
              v-model="item.isInspected"
            />
          </template>
          <template v-slot:item.inspectDate="{ item }">
            {{ convertDate(item.inspectDate) }}
          </template>
          <template v-slot:item.isRepairRequired="{ item }">
            {{ getRepairRequired(item.isRepairRequired) }}
          </template>
          <template v-slot:item.inspectAct="{ item }">
            <v-btn
              v-if="isVisibleButton(item.inspectAct)"
              small
              color="success"
              @click="() => downloadFile(item.inspectAct.guid)"
            >
              Выгрузить
            </v-btn>
          </template>
          <template v-slot:item.isEstimated="{ item }">
            <v-checkbox
              disabled
              class="d-inline-block"
              v-model="item.isEstimated"
            />
          </template>
          <template v-slot:item.estimateDate="{ item }">
            {{ convertDate(item.estimateDate) }}
          </template>
          <template v-slot:item.estimate="{ item }">
            <v-btn
              v-if="isVisibleButton(item.estimate)"
              small
              color="success"
              @click="() => downloadFile(item.estimate.guid)"
            >
              Выгрузить
            </v-btn>
          </template>
          <template v-slot:item.isRepaired="{ item }">
            <v-checkbox
              disabled
              class="d-inline-block"
              v-model="item.isRepaired"
            />
          </template>
          <template v-slot:item.repairDate="{ item }">
            {{ convertDate(item.repairDate) }}
          </template>
          <template v-slot:item.repairAct="{ item }">
            <v-btn
              v-if="isVisibleButton(item.repairAct)"
              small
              color="success"
              @click="() => downloadFile(item.repairAct.guid)"
            >
              Выгрузить
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="isChangeEntry(item._permission)"
              small
              class="mr-2"
              @click="() => handleEditItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </VDataTable>
      </VueCustomScrollbar>
    </template>

    <ConditionEditModal
      v-if="editedItem && modal"
      :isOpen="modal"
      :house-id="houseId"
      :edited-id="editedId"
      :editedItem="editedItem"
      @close="handleCloseModal"
    />
  </div>
</template>

<script>
import moment from "moment";
import VueCustomScrollbar from "vue-custom-scrollbar";
import ConditionEditModal from "@/components/Ventilation/ConditionEditModal";
import PlaceholderPage from "@/components/Overhaul/PlaceholderPage";
import { scrollbarSettings } from "@/constants";
import { CONDITION_DEFAULT_VALUES } from "@/constants/variables";

export default {
  name: "Condition",
  components: {
    VueCustomScrollbar,
    ConditionEditModal,
    PlaceholderPage,
  },
  apollo: {
    getAddressSuggestions: {
      query: require("@/graphql/getAddressSuggestions.gql"),
      fetchPolicy: "network-only",
      skip() {
        return !this.search;
      },
      variables() {
        return {
          address: this.search,
        };
      },
      result(response) {
        const { data: { getAddressSuggestions } } = response;

        this.addresses = getAddressSuggestions;
        this.addressLoading = false;
      }
    },
    getHouseVentilation: {
      query: require("@/graphql/getHouseVentilation.gql"),
      fetchPolicy: "network-only",
      skip() {
        return !this.address;
      },
      variables() {
        const test = true;
        return {
          address: this.address.address,
          houseFiasGuid: this.address.houseGuid,
          houseNumber: this.address.houseNumber,
          streetFiasGuid: this.address.streetGuid,
          ...test ? {
            houseFiasGuid: "000007bd-0ea2-4d5a-ad10-1b1d005ff656",
          } : {},
        };
      },
      result(response) {
        const { data: { getHouseVentilation } } = response;
        this.tableData.items = getHouseVentilation?.ventilationRepairProcesses || [];
        this.addEntryPermission = getHouseVentilation?._permission?.addVentilationProcess?.result || false;
        console.log('getHouseVentilation',getHouseVentilation)
        this.houseId = getHouseVentilation?.id || 0;
        this.cards = {
          buildingYear: getHouseVentilation?.buildingYear || "",
          square: getHouseVentilation?.square || "",
          floorsCount: getHouseVentilation?.floorsCount || "",
          entrancesCount: getHouseVentilation?.entrancesCount || "",
          apartmentsCount: getHouseVentilation?.apartmentsCount || "",
          hasGasStoven: getHouseVentilation?.hasGasStoven ? "Да" : "Нет",
          hasWaterHeaters: getHouseVentilation?.hasWaterHeaters ? "Да" : "Нет",
          hasGasBoilers: getHouseVentilation?.hasGasBoilers ? "Да" : "Нет",
        };

        this.loading = false;
      },
    },
  },
  data() {
    return {
      addEntryPermission: false,
      address: "",
      addresses: [],
      addressLoading: false,
      search: null,
      loading: false,
      cards: {
        buildingYear: "",
        square: "",
        floorsCount: "",
        entrancesCount: "",
        apartmentsCount: "",
        hasGasStoven: "",
        hasWaterHeaters: "",
        hasGasBoilers: "",
      },
      modal: false,
      houseId: null,
      editedId: 0,
      editedItem: { ...CONDITION_DEFAULT_VALUES },
      tableData: {
        headers: [
          { text: "Обследование проведено", value: "isInspected", align: "center" },
          { text: "Дата обследования", value: "inspectDate", align: "center" },
          { text: "Результат обследования", value: "isRepairRequired", align: "center" },
          { text: "Описание требуемого ремонта", value: "repairDescription", align: "center" },
          { text: "Акт обследования", value: "inspectAct", align: "center" },
          { text: "Проведено осмечивание", value: "isEstimated", align: "center" },
          { text: "Дата осмечивания", value: "estimateDate", align: "center" },
          { text: "Сумма затрат по смете, руб.", value: "estimatedCost", align: "center" },
          { text: "Смета", value: "estimate", align: "center" },
          { text: "Выполнен ремонт", value: "isRepaired", align: "center" },
          { text: "Сумма затрат фактическая, руб.", value: "factualCost", align: "center" },
          { text: "Дата ремонта", value: "repairDate", align: "center" },
          { text: "Акт КС-2", value: "repairAct", align: "center" },
          { text: "", value: "actions", align: "center" },
        ],
        items: [],
      },
    };
  },
  computed: {
    scrollbarSettings: () => scrollbarSettings,
    isShowData() {
      return this.address && !this.loading;
    },
    placeholderText() {
      return this.loading ? 'Загружаем данные' : 'Введите адрес многоквартирного дома'
    },
    placeholderHouseText() {
      return this.loading ? 'Загружаем данные' : 'Дом в базе не обнаружен'
    },
    items() {
      return this.tableData?.items?.filter(item => {
        return item?.isInspected || item?.isEstimated || item?.isRepaired;
      }) || [];
    },
    widgets() {
      return [
        { title: "Год постройки", value: this.cards.buildingYear },
        { title: "Общая площадь, м2", value: this.cards.square },
        { title: "Кол-во этажей", value: this.cards.floorsCount },
        { title: "Кол-во подъездов", value: this.cards.entrancesCount },
        { title: "Кол-во квартир", value: this.cards.apartmentsCount },
        { title: "Наличие газовых плит", value: this.cards.hasGasStoven },
        { title: "Наличие газовых колонок", value: this.cards.hasWaterHeaters },
        { title: "Наличие газовых котлов", value: this.cards.hasGasBoilers },
      ];
    },
  },
  watch: {
    async address() {
      this.loading = true;
    },
  },
  methods: {
    convertDate(date) {
      return moment(date).isValid() ? moment(date).format("DD.MM.YYYY") : "";
    },
    getRepairRequired(isRepairRequired) {
      return isRepairRequired ? "Требуется ремонт" : "В рабочем состоянии";
    },
    handleEditItem(item = null) {
      this.editedId = item?.id || 0;
      this.houseId = this.houseId || null;
      this.editedItem = item || { ...CONDITION_DEFAULT_VALUES };
      this.modal = true;
    },
    handleCloseModal(successful = false) {
      this.modal = false;
      this.editedId = 0;
      this.editedItem = { ...CONDITION_DEFAULT_VALUES };

      if (successful) {
        this.$apollo.queries.getHouseVentilation.refetch();
      }
    },
    handleSaveItem() {
      this.handleCloseModal();
    },
    downloadFile(guid) {
      const link = `https://sa.yurtarb.ru/download/${ guid }`;
      window.open(link);
    },
    handleDownloadTable() {
      this.$apollo.mutate({
        mutation: require("@/graphql/generateVentilationRepairRegister.gql"),
        variables: {
          houseId: this.houseId,
        },
      })
        .then((response) => {
          const { data: { generateVentilationRepairRegister: { guid } } } = response;
          if (guid) {
            this.downloadFile(guid);
          }
        });
    },
    isVisibleButton(object) {
      return "guid" in (object || {});
    },
    isChangeEntry(object) {
      return typeof object !== 'undefined' ? object.change?.result : false;
    },
  },
};
</script>

<style module>
.widgets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 24px;
  margin: 24px 0;
}
</style>
