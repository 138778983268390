<template>
  <v-col cols="12" sm="8" md="7">
    <v-row>
      <v-col cols="12" sm="3">
        <v-autocomplete
          clearable
          dense
          type="select"
          filled
          rounded
          hide-details
          class="text-subtitle-2"
          placeholder="отрасль"
          :items="industry"
          item-text="fullName"
          item-value="objectId"
          v-model="selectedIndustry"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-autocomplete
          clearable
          dense
          type="select"
          filled
          rounded
          hide-details
          class="text-subtitle-2"
          placeholder="программа"
          :items="program"
          item-text="fullName"
          item-value="objectId"
          v-model="selectedProgram"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-autocomplete
          clearable
          dense
          type="select"
          filled
          rounded
          hide-details
          class="text-subtitle-2"
          placeholder="вид объекта"
          :items="typeObject"
          item-text="fullName"
          item-value="objectId"
          v-model="selectedTypeObject"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-autocomplete
          clearable
          dense
          type="select"
          filled
          rounded
          hide-details
          class="text-subtitle-2"
          placeholder="Выберите регион"
          :items="region"
          item-text="fullName"
          item-value="objectId"
          v-model="selectedRegion"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    onlyYear: {
      type: Boolean,
      default: false
    },
    region: {
      type: Array,
      default: () => [
        { "objectId": "all", "fullName": "Республика Башкортостан"},
        {
          "objectId": 95231299,
          "fullName": "г.о. город Уфа",
        },
        {
          "objectId": 95230709,
          "fullName": "г.о. город Агидель",
        },
        {
          "objectId": 95231300,
          "fullName": "г.о. город Кумертау",
        },
        {
          "objectId": 95231301,
          "fullName": "г.о. город Нефтекамск",
        },
        {
          "objectId": 95230429,
          "fullName": "г.о. город Октябрьский",
        },
        {
          "objectId": 95230579,
          "fullName": "г.о. город Салават",
        },
        {
          "objectId": 95231302,
          "fullName": "г.о. город Сибай",
        },
        {
          "objectId": 95230428,
          "fullName": "г.о. город Стерлитамак",
        },
        {
          "objectId": 95230726,
          "fullName": "г.о. ЗАТО город Межгорье",
        },
        {
          "objectId": 95230672,
          "fullName": "м.р-н Абзелиловский",
        },
        {
          "objectId": 95230688,
          "fullName": "м.р-н Альшеевский",
        },
        {
          "objectId": 95230630,
          "fullName": "м.р-н Архангельский",
        },
        {
          "objectId": 95230445,
          "fullName": "м.р-н Аскинский",
        },
        {
          "objectId": 95231109,
          "fullName": "м.р-н Аургазинский",
        },
        {
          "objectId": 95230897,
          "fullName": "м.р-н Баймакский",
        },
        {
          "objectId": 95231046,
          "fullName": "м.р-н Бакалинский",
        },
        {
          "objectId": 95230881,
          "fullName": "м.р-н Балтачевский",
        },
        {
          "objectId": 95230990,
          "fullName": "м.р-н Белебеевский",
        },
        {
          "objectId": 95230643,
          "fullName": "м.р-н Белокатайский",
        },
        {
          "objectId": 95230797,
          "fullName": "м.р-н Белорецкий",
        },
        {
          "objectId": 95231145,
          "fullName": "м.р-н Бижбулякский",
        },
        {
          "objectId": 95230510,
          "fullName": "м.р-н Бирский",
        },
        {
          "objectId": 95231093,
          "fullName": "м.р-н Благоварский",
        },
        {
          "objectId": 95230542,
          "fullName": "м.р-н Благовещенский",
        },
        {
          "objectId": 95231172,
          "fullName": "м.р-н Буздякский",
        },
        {
          "objectId": 95231269,
          "fullName": "м.р-н Бураевский",
        },
        {
          "objectId": 95230727,
          "fullName": "м.р-н Бурзянский",
        },
        {
          "objectId": 95230598,
          "fullName": "м.р-н Гафурийский",
        },
        {
          "objectId": 95230476,
          "fullName": "м.р-н Давлекановский",
        },
        {
          "objectId": 95230740,
          "fullName": "м.р-н Дуванский",
        },
        {
          "objectId": 95230526,
          "fullName": "м.р-н Дюртюлинский",
        },
        {
          "objectId": 95230962,
          "fullName": "м.р-н Ермекеевский",
        },
        {
          "objectId": 95231283,
          "fullName": "м.р-н Зианчуринский",
        },
        {
          "objectId": 95230847,
          "fullName": "м.р-н Зилаирский",
        },
        {
          "objectId": 95230861,
          "fullName": "м.р-н Иглинский",
        },
        {
          "objectId": 95231185,
          "fullName": "м.р-н Илишевский",
        },
        {
          "objectId": 95230461,
          "fullName": "м.р-н Ишимбайский",
        },
        {
          "objectId": 95231064,
          "fullName": "м.р-н Калтасинский",
        },
        {
          "objectId": 95230580,
          "fullName": "м.р-н Караидельский",
        },
        {
          "objectId": 95231208,
          "fullName": "м.р-н Кармаскалинский",
        },
        {
          "objectId": 95230787,
          "fullName": "м.р-н Кигинский",
        },
        {
          "objectId": 95230615,
          "fullName": "м.р-н Краснокамский",
        },
        {
          "objectId": 95231008,
          "fullName": "м.р-н Кугарчинский",
        },
        {
          "objectId": 95231159,
          "fullName": "м.р-н Кушнаренковский",
        },
        {
          "objectId": 95231238,
          "fullName": "м.р-н Куюргазинский",
        },
        {
          "objectId": 95231251,
          "fullName": "м.р-н Мелеузовский",
        },
        {
          "objectId": 95231225,
          "fullName": "м.р-н Мечетлинский",
        },
        {
          "objectId": 95230430,
          "fullName": "м.р-н Мишкинский",
        },
        {
          "objectId": 95230494,
          "fullName": "м.р-н Миякинский",
        },
        {
          "objectId": 95230754,
          "fullName": "м.р-н Нуримановский",
        },
        {
          "objectId": 95231029,
          "fullName": "м.р-н Салаватский",
        },
        {
          "objectId": 95230710,
          "fullName": "м.р-н Стерлибашевский",
        },
        {
          "objectId": 95230941,
          "fullName": "м.р-н Стерлитамакский",
        },
        {
          "objectId": 95230833,
          "fullName": "м.р-н Татышлинский",
        },
        {
          "objectId": 95230408,
          "fullName": "м.р-н Туймазинский",
        },
        {
          "objectId": 95230559,
          "fullName": "м.р-н Уфимский",
        },
        {
          "objectId": 95230921,
          "fullName": "м.р-н Учалинский",
        },
        {
          "objectId": 95230657,
          "fullName": "м.р-н Федоровский",
        },
        {
          "objectId": 95230818,
          "fullName": "м.р-н Хайбуллинский",
        },
        {
          "objectId": 95230976,
          "fullName": "м.р-н Чекмагушевский",
        },
        {
          "objectId": 95231076,
          "fullName": "м.р-н Чишминский",
        },
        {
          "objectId": 95231131,
          "fullName": "м.р-н Шаранский",
        },
        {
          "objectId": 95230767,
          "fullName": "м.р-н Янаульский",
        }
      ]
    },
    industry: {
      type: Array,
      default: () => [
        {"objectId": "Коммунальное хозяйство", "fullName": "Коммунальное хозяйство"},
        {"objectId": "Дороги", "fullName": "Дороги"},
        {"objectId": "Социальные объекты", "fullName": "Социальные объекты"},
      ]
    },
    program: {
      type: Array,
      default: () => [{"objectId": "Чистая вода", "fullName": "Чистая вода"}]
    },
    typeObject: {
      type: Array,
      default: () => [
        {"objectId": "Сети водоснабжения", "fullName": "Сети водоснабжения"},
        {"objectId": "Очистные сооружения", "fullName": "Очистные сооружения"},
        {"objectId": "Котельные", "fullName": "Котельные"},
      ]
    },
    firstDate: {
      type: [String, Number],
      default: null
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    defaultDate: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      menu: false,
      togglePeriod: undefined,
      inputDate: '',
      date: ['01.01.1970'],
      dateIndex: 0,
      selectedRegion: 'all',
      selectedIndustry: [],
      selectedProgram: 'Чистая вода',
      selectedTypeObject: [],
    }
  },
  computed: {
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
    dateFormatted: {
      get() {
        return this.inputDate;
      },
      set(val) {
        if (typeof(val) === 'string') {
          let matrix = '__.__.____ - __.__.____',
            i = 0,
            date = val.replace(/\D/g, '');

          const result = matrix.replace(/./g, (a) => {
            return (/[_]/.test(a) && i < date.length) ? date.charAt(i++) : i >= date.length ? '' : a;
          });

          (val.length > matrix.length)
          ? this.inputDate = result + val.substr(matrix.length)
          : this.inputDate = result;
        } else {
          this.inputDate = val.map(item => new Date(Date.parse(item)).toLocaleDateString('ru-RU')).join(' - ');
        }
      }
    },
    filters() {
      if (this.date.length === 2) {
        return {
          period: this.date.map(item => new Date(Date.parse(item)).toLocaleDateString('ru-RU')),
          region: this.selectedRegion,
        };
      } else {
        return {
          period: [],
          region: this.selectedRegion,
        };
      }
    }
  },
  methods: {
    dateClickEmit(e) {
      let date = new Date(e);
      let firstDay = null;
      let secondDay = null;
      console.log(((7 + 6 - date.getDay()) % 7) > 0)
      if (((7 + 6 - date.getDay()) % 7) > 0) {
        firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7) - 6).toISOString().substr(0, 10);
        secondDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7)).toISOString().substr(0, 10);
      } else {
        firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7)+1).toISOString().substr(0, 10);
        secondDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7)+7).toISOString().substr(0, 10);
      }
      if (this.defaultDate) {
        this.date = [firstDay, secondDay];
      }
    },
    saveYear(val) {
      this.dateFormatted = `01.01.${val} - 31.12.${val}`;
      this.menu = false;
      this.parseDate()
    },
    clearDate() {
      this.date = [];
      this.inputDate = '';
    },
    preventNotDigit(e) {
      if (e.key.match(/\D/ig)) {
        e.preventDefault();
      }
    },
    convertDate(date) {
      return `${date.substr(6)}-${date.substr(3, 2)}-${date.substr(0, 2)}`;
    },
    reverseDate(date) {
      let arr;

      (typeof(date) === 'string')
      ? arr = date.split(' - ').map(item => this.convertDate(item))
      : arr = date;

      if (Date.parse(arr[0]) > Date.parse(arr[1])) {
        return arr.reverse();
      } else {
        return arr;
      }
    },
    parseDate() {
      if (this.menu) return;
      if (this.dateFormatted.length !== 23) {
        this.dateFormatted = this.date;
      } else {
        const arr = this.dateFormatted.split(' - '),
              newDate = [];
        let isValid = true;

        arr.forEach(item => {
          const currDate = this.convertDate(item),
                parse = Date.parse(currDate),
                currentCetury = 946666800000,
                afterFiveYear = Date.parse(new Date()) + 157248000000;

          if (isNaN(parse) || parse < currentCetury || parse > afterFiveYear) {
            isValid = false;
          } else {
            newDate.push(new Date(parse).toISOString().substr(0, 10));
          }
        });

        isValid
          ? this.date = newDate
          : this.dateFormatted = this.date;
      }
    },
    setDefaultDate(date) {
      let firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7) - 13).toISOString().substr(0, 10);
      let secondDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7) - 7).toISOString().substr(0, 10);

      return [firstDay, secondDay];
    },
  },
  watch: {
    selectedRegion() {
      this.$emit('filter-updated', 'region', this.selectedRegion)
    },
    selectedIndustry() {
      this.$emit('filter-updated', 'industry', this.selectedIndustry)
    },
    selectedProgram() {
      this.$emit('filter-updated', 'program', this.selectedProgram)
    },
    selectedTypeObject() {
      this.$emit('filter-updated', 'typeObject', this.selectedTypeObject)
    },
  },
  mounted() {

  }
}
</script>

<style>

</style>
