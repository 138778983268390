<template>
  <ChartWrapperVent :title="title" :subTitle="subTitle" :titleMaxWidth="titleMaxWidth" :meaning="meaning">
    <apexchart
      height="210"
      :options="chartOptions"
      :series="meaning.data.series"
      @dataPointSelection="dataPointSelection"
      :data-key="meaning.data.key"
    />
  </ChartWrapperVent>
</template>

<script>
import ChartWrapperVent from '@/components/Ventilation/ChartWrapperVent';

export default {
  name: 'VentilationDiagram',
  components: {
    ChartWrapperVent,
  },
  props: {
    dataPointSelect: {
      type: Boolean,
      default: false
    },
    titleMaxWidth: {
      type: String,
      default: '60%',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    themeMode: {
      type: String,
      default: 'light',
    },
    meaning: {
      type: Object,
      default: () => {
        return {
          sideTexts: [],
          data: {
            key: '',
            series: [],
            labels: []
          },
          colors: [],
        }
      }
    },
    pen: {
      type: String,
      default: 'all'
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'donut'
        },
        colors: this.meaning.colors,
        states: {
          active: {
            filter: {
              type: 'none',
            }
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '50%',
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  color: this.themeMode==='light' ? '#373d3f' : '#fff',
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Всего',
                  fontSize: '16px',
                  fontWeight: 600,
                }
              }
            }
          },
        },
        legend: { show: false },
        labels: this.meaning.data.labels,
        tooltip: {
          enabled: true,
          // y: {
          //   formatter: function(value) {
          //     value = '';
          //     return value
          //   }
          // },
          style: {
            fontSize: '16px'
          }
        },
      }
    }
  },
  methods: {
    dataPointSelection(event, chartContext, config) {
        const type = config.dataPointIndex,
              key = chartContext.el.dataset.key;
      if (this.dataPointSelect) {
        this.$emit('dataPointSelection', type, key);
      } else {
        this.$emit('clearSelection', type, key);
      }
    },
  }
};
</script>
<style lang="scss">
.apexcharts-canvas {
  svg {
    width: 100%;
  }
}
._pen-none {
  pointer-events: none;
}
</style>
