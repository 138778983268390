<template>
  <div id="overhaul-map" :style="{ 'height' : '100%', 'z-index': '3' }">
  </div>
</template>

<script>
import L from "leaflet";

export default {
  data() {
    return {
      map: null,
    }
  },
  props: {
    themeMode: {
      type: String,
      default: 'light',
    },
    mapData: {
      type: Object,
      default: () => {
        return {
          typename: 'Данные отсутствуют',
          items: [],
        }
      } 
    },
    mapCenter: {
      type: Array,
      default: ()=>{
        return [54.74728667873139, 55.9947550905636]
      }
    }
    },
  methods: {
    updateMap() {
      this.map.remove();
      this.map = null;
      this.initMap();
    },
      initMap() {
      let mymap = L.map('overhaul-map').setView(this.mapCenter, 11);

      L.tileLayer(this.$root.urlMap, {
        foo: 'bar',
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
      }).addTo(mymap);
      
        this.map = mymap;

        if (this.mapData.typename !== null) {
          switch (this.mapData.typename) {
            case 'Данные отсутствуют':
              this.mapWorksRender(this.mapData, 'Данные отсутствуют');
              break;
            case 'WorkTypeDashboard':
              this.mapWorksRender(this.mapData, 'Виды работ');
              break;
            case 'Entrance':
              this.mapEntrancesRender(this.mapData, 'Подъезды');
              break;
            case 'VentilationDashboard':
              this.mapVentilationRender(this.mapData, '');
              break;
          }
        }
    },
    mapWorksRender(mapData, title) {
        // let redIcon = L.icon({
        //   iconUrl: './img/icons/pin_red.png',
        //   iconSize: [48, 48],
        //   iconAnchor: [24, 48],
        //   popupAnchor: [0, -48]
        // });
        let blueIcon = L.icon({
          iconUrl: '../img/icons/pin_blue.png',
          iconSize: [48, 48],
          iconAnchor: [24, 48],
          popupAnchor: [0, -48]
        });
        // let greenIcon = L.icon({
        //   iconUrl: './img/icons/pin_green.png',
        //   iconSize: [48, 48],
        //   iconAnchor: [24, 48],
        //   popupAnchor: [0, -48]
        // });
      mapData.items.forEach(mapDataItem=>{
        let coord;
        coord = mapDataItem.coord ? mapDataItem.coord : [54.74728667873139, 55.9947550905636];
        let mapBaloonBody = '';
        if (mapDataItem.worksByTypes !== null) {
          mapDataItem.worksByTypes.forEach(work => {
            mapBaloonBody += `<li><div>${work.type}</div><div>${work.amount}</div></li>`
          });
        }
        let mapBaloonWrapper = `
          <div class="overhaul-baloon" style="min-width: 280px">
            <div class="overhaul-baloon__head">
              <h2 class="overhaul-baloon__title" style="text-align: center">${title}</h2>
              <ul style="padding: 0;" class="overhaul-baloon__totals">
                <li class="overhaul-baloon__total"><div>Всего работ</div><div>${mapDataItem.totalWorks}</div></li>
                <li class="overhaul-baloon__total"><div>Всего домов</div><div>${mapDataItem.totalHouses}</div></li>
              </ul>
            </div>
            <div class="overhaul-baloon__body">
              <ul style="padding: 0;" class="overhaul-baloon__list">
                <li class="overhaul-baloon__li overhaul-baloon__li_first"><div>Вид</div><div>Кол-во</div></li>
                ${mapBaloonBody}
              </ul>
            </div>
          </div>
        `;

        let marker = L.marker(coord, {
          'title': title,
          'icon': blueIcon
        });
        marker.addTo(this.map);
        marker.bindPopup(mapBaloonWrapper);
      })
    },
    mapEntrancesRender(mapData, title) {
        let blueIcon = L.icon({
          iconUrl: '../img/icons/pin_blue.png',
          iconSize: [48, 48],
          iconAnchor: [24, 48],
          popupAnchor: [0, -48]
        });
      mapData.items.forEach(mapDataItem=>{
        let coord;
        coord = mapDataItem.coord ? mapDataItem.coord : [54.74728667873139, 55.9947550905636];
        let mapBaloonWrapper = `
          <div class="overhaul-baloon" style="min-width: 280px">
            <div class="overhaul-baloon__head">
              <h2 class="overhaul-baloon__title" style="text-align: center">${title}</h2>
              <ul style="padding: 0;" class="overhaul-baloon__totals">
                <li class="overhaul-baloon__total"><div>Всего домов</div><div>${mapDataItem.houses}</div></li>
                <li class="overhaul-baloon__total"><div>Всего Подъездов</div><div>${mapDataItem.entrances}</div></li>
              </ul>
            </div>
          </div>
        `;

        let marker = L.marker(coord, {
          'title': title,
          'icon': blueIcon
        });
        marker.addTo(this.map);
        marker.bindPopup(mapBaloonWrapper);
      })
    },
    mapVentilationRender(mapData, title) {
        let redIcon = L.icon({
          iconUrl: '../img/icons/pin_red.png',
          iconSize: [48, 48],
          iconAnchor: [24, 48],
          popupAnchor: [0, -48]
        });
        let blueIcon = L.icon({
          iconUrl: '../img/icons/pin_blue.png',
          iconSize: [48, 48],
          iconAnchor: [24, 48],
          popupAnchor: [0, -48]
        });
        let greenIcon = L.icon({
          iconUrl: '../img/icons/pin_green.png',
          iconSize: [48, 48],
          iconAnchor: [24, 48],
          popupAnchor: [0, -48]
        });
        let noIcon = L.icon({
          iconUrl: '../img/icons/pin_gray.png',
          iconSize: [48, 48],
          iconAnchor: [24, 48],
          popupAnchor: [0, -48]
        });
        mapData.items.forEach((item, index)=>{
          if (!item.geo) {
            return;
          }
          let itemGeo = typeof item.geo === 'string' ?  item.geo.replaceAll(' ', '').split(',') : item.geo;
          if (itemGeo) {
            let lat = itemGeo[0];
            let lng = itemGeo[1];
            mapData.items.forEach((itemm, indexx)=>{
              if (!itemm.geo) {
                return;
              }
              let itemmGeo = typeof itemm.geo === 'string' ?  itemm.geo.replaceAll(' ', '').split(',') : itemm.geo;
              let latt = itemmGeo[0];
              let lngg = itemmGeo[1];
              if (indexx !== index) {
                if (lat === latt && lng === lngg) {
                  itemm.geo = [parseInt(latt)+0.0009, parseInt(lngg)+0.0009];
                }
              }
            })
          }
        })
        mapData.items.forEach(mapDataItem => {
        let markerType = null;
        if (mapDataItem.hasGasStoven === true && mapDataItem.hasWaterHeaters === false && mapDataItem.hasGasBoilers === false) {
          markerType = greenIcon;
        } else if (mapDataItem.hasGasStoven === true && mapDataItem.hasWaterHeaters === true && mapDataItem.hasGasBoilers === false) {
          markerType = blueIcon;
        } else if (mapDataItem.hasGasStoven === true && mapDataItem.hasWaterHeaters === false && mapDataItem.hasGasBoilers === true) {
          markerType = redIcon;
        } else {
          markerType = noIcon;
        }
        let mapDataItemStr = '';
        let color = this.themeMode === 'light' ? '#111' : '#ddd';
        if (mapDataItem.gasShutdowns) {
          mapDataItem.gasShutdowns.forEach(e => {
            let periodStart = e.periodStart ? this.dateFormatter(e.periodStart) : 'нет данных';
            // let periodEnd = e.periodEnd ? e.periodEnd.replaceAll('-', '.') : 'нет данных';
            let reason = e.reason;
            let shutdownedApartmentsCount = e.shutdownedApartmentsCount !== (null&&undefined) ? e.shutdownedApartmentsCount : '0';
            let restoredApartmentsCount = e.restoredApartmentsCount !== (null&&undefined) ? e.restoredApartmentsCount : '0';
            let shutdownedGasBoilersCount = e.shutdownedGasBoilersCount !== (null&&undefined) ? e.shutdownedGasBoilersCount : '0';
            let restoredGasBoilersCount = e.restoredGasBoilersCount !== (null&&undefined) ? e.restoredGasBoilersCount : '0';
            let shutdownedGasHeatersCount = e.shutdownedGasHeatersCount !== (null&&undefined) ? e.shutdownedGasHeatersCount : '0';
            let restoredGasHeatersCount = e.restoredGasHeatersCount !== (null&&undefined) ? e.restoredGasHeatersCount : '0';
            let shutdownedGasStoveCount = e.shutdownedGasStoveCount !== (null&&undefined) ? e.shutdownedGasStoveCount : '0';
            let restoredGasStoveCount = e.restoredGasStoveCount !== (null&&undefined) ? e.restoredGasStoveCount : '0';
            mapDataItemStr += `
                  <div class="overhaul-baloon__head" style="margin-bottom: 10px;">
                    <div class="overhaul-baloon__green"><span>Дата отключения газоснабжения:</span><span>${periodStart}</span></div>
                  </div>
                  <ul style="padding: 0;" class="overhaul-baloon__totals">
                    <li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Причина:</span> <span style="color: ${color};">${reason}</span></li>
                    <li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Отключено/восстановлено квартир:</span> <span style="color: ${color}; text-align: right;">${shutdownedApartmentsCount}/${restoredApartmentsCount}</span></li>
                    <li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Отключено/восстановлено ГП:</span> <span style="color: ${color}; text-align: right;">${shutdownedGasStoveCount}/${restoredGasStoveCount}</span></li>
                    <li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Отключено/восстановлено ГК:</span> <span style="color: ${color}; text-align: right;">${shutdownedGasHeatersCount}/${restoredGasHeatersCount}</span></li>
                    <li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Отключено/восстановлено ГКотл:</span> <span style="color: ${color}; text-align: right;">${shutdownedGasBoilersCount}/${restoredGasBoilersCount}</span></li>
                  </ul>
            `
          })
          let coord = null;
          if (mapDataItem.geo) {
            coord = typeof mapDataItem.geo === 'string' ? mapDataItem.geo.replaceAll(' ', '').split(',') : mapDataItem.geo;
          } else {
            coord = [54.74728667873139, 55.9947550905636];
          }
          let mapDataItemSubStr = '';

          mapDataItem.managmentOrganization ? mapDataItemSubStr+= `<li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Способ управления МКД:</span> <span style="color: ${color}; text-align: right;">${mapDataItem.managmentOrganization}</span></li>` : null;
          mapDataItem.inspectionResult ? mapDataItemSubStr+= `<li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Способ управления МКД:</span> <span style="color: ${color}; text-align: right;">${mapDataItem.inspectionResult}</span></li>` : null;
          mapDataItem.cost ? mapDataItemSubStr+= `<li class="overhaul-baloon__total" style="margin-bottom: 10px;"><span>Способ управления МКД:</span> <span style="color: ${color}; text-align: right;">${mapDataItem.cost}</span></li>` : null;

          let mapBaloonWrapper = `
              <div class="overhaul-baloon" style="min-width: 280px">
                <h2 class="overhaul-baloon__title">${mapDataItem.address}</h2>
                <div class="overhaul-baloon__body" style="margin-bottom: 20px">
                  ${mapDataItemStr}
                </div>
                <ul style="padding: 0;" class="overhaul-baloon__totals">
                  ${mapDataItemSubStr}
                </ul>
              </div>
            `;
  
          let marker = L.marker(coord, {
            'title': title,
            'icon': markerType
          });
          marker.addTo(this.map);
          marker.bindPopup(mapBaloonWrapper);
        }
      })

    },

    dateFormatter(date) {
      const [year, month, day] = date?.split('-');
        return `${day}.${month}.${year}`;
    }
  },
  mounted() {
    this.initMap();
  },
  watch: {
    themeMode() {
      if (this.map) {
        this.updateMap();
      }
    },
    mapData() {
      if (this.map) {
        this.updateMap();
      }
    }
  }
  }
</script>

<style lang="scss">
  .leaflet-layer,
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-control-attribution {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  }

@media (min-width:1360px){
  #overhaul-map {
    min-height: 500px;
  }
}
@media (max-width:950px) {
  #overhaul-map {
      min-height: 500px;
    }
}

.theme--dark {
  .leaflet-popup-content-wrapper {
  background-color: #202020;
  color: #fff;
  }
  .leaflet-popup-tip {
    display: none;
  }
}

.overhaul-baloon {

  &__head {
    padding: 0 0 10px;
    border-bottom: 1px solid #fff;
    }

  &__green {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background-color: rgba(0, 128, 0, 0.3);
    border-radius: 30px;
    font-size: 0.8rem;
  }

  &__title {
    font-size: 1.2rem;
  }

  &__subtitle {
    display: block;
    font-size: 0.9rem;
  }

  &__totals {
    font-size: 1rem;
  }

  &__total {
    display: flex;
    column-gap: 15px;
    justify-content: space-between;
    &:not(:last-child) {margin-bottom: 7px;}
  }

  &__body {
    padding-top: 10px;
    font-size: 1rem;
    max-height: 300px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list {
    padding: 0;
    li {
      display: flex;
      column-gap: 15px;
      justify-content: space-between;
    
      &:not(:last-child) {
        margin-bottom: 7px;
      }

    }
  }

  &__li {

    &_first {}
  }
}
</style>