<template>
  <div 
    @click="clearSelection"
  >
    <VRow justify="space-between">
      <VCol cols="12" md="6" lg="5">
        <div class="text-h4">Дымоходы/Вентканалы (Д/В)</div>
      </VCol>
      <OutagesFilters @filter-updated="updateFilter" :defaultDate="defaultDate" :region="regions" :maxDate="maxDate" :minDate="minDate" :placeholder="'Республика Башкортостан'" />
    </VRow>
    
    <PlaceholderPage
      v-if="filterEmpty || pending"
      :text="placeholderText"
    />

    <template 
      v-else
    >
      <div class="outages__top top-outages_five">
        <div v-for="(card, i) in cardStats" :key="i" class="top-outages__item">
          <div v-if="card.head" class="unit__head">{{ card.head }}</div>
          <div
            :class="['d-flex flex justify-space-between unit unit--stats', {
              'align-end': card.alignEnd,
            },
              $style.unitMh
              ]"
          >
            <div class="unit__col">
              <div class="unit__title">{{ card.left.caption }}</div>
              <div class="unit__value text-h5 font-weight-semibold">{{ card.left.value }}</div>
              <div v-if="card.left.bottom" class="unit__value text-h5 font-weight-semibold">{{ card.left.bottom }}</div>
            </div>
            <div
              :class="['unit__col unit__col--right', {
                'justify-space-between fill-height': card.right.fillHeight,
              }]"
            >
              <div class="unit__title">{{ card.right.caption }}</div>
              <div
                :class="['unit__value text-h5 font-weight-semibold', {
                'unit__value--end': card.alignEnd, 'unit_tar': card.right }]">
                {{ card.right.value }}
              </div>
              <div v-if="card.right.bottom" class="unit__value text-h5 font-weight-semibold">{{ card.right.bottom }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-ventilation">
        <div class="grid-ventilation__item item-gridVentilation">
          <div class="item-gridVentilation__head unit">
            Аварийные отключения
          </div>
          <div class="item-gridVentilation__body">
            <div class="grid-outages__item">
              <div
              class="flex-grow-1 onLightShadow"
              :class="$style.unit"
              >
                <VentilationDiagram
                  @dataPointSelection="newUpdateMap"
                  title="Отключено газоснабжение в МКД"
                  subTitle="(на текущую дату)"
                  :meaning="diagramDataOnMoment"
                  :theme-mode="themeMode"
                  titleMaxWidth="100%"
                  :ref="diagramDataOnMoment.data.key"
                  :dataPointSelect="true"
                />
              </div>
            </div>
            <div class="grid-outages__item">
              <div
              class="flex-grow-1 onLightShadow"
              :class="$style.unit"
              >
                <VentilationDiagram
                  @clearSelection="clearSelectionTwo"
                  title="Восстановлено газоснабжение в МКД"
                  subTitle="(за отчётный период)"
                  :meaning="diagramDataOnPeriod"
                  :theme-mode="themeMode"
                  titleMaxWidth="100%"
                  :ref="diagramDataOnPeriod.data.key"
                  :pen="'_pen-none'"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="grid-ventilation__item item-gridVentilation">
          <div class="item-gridVentilation__head unit">
            Плановые работы
          </div>
          <div class="item-gridVentilation__body">
            <div class="grid-outages__item">
              <div class="flex-grow-1 onLightShadow" :class="$style.unit">
                <Speedometer
                  title="Обследование тех.состояния Д/В в МКД"
                  subTitle="(3 раза в год)"
                  titleMaxWidth="100%"
                  :meaning="speedometer"
                  :theme-mode="themeMode"
                  :value="speedometerValue"
                  :maxValue="speedometerMaxValue"
                />
              </div>
            </div>
            <div class="grid-outages__item">
              <div class="flex-grow-1 onLightShadow" 
                :class="$style.unit"
              >
                <VentilationBarChart
                  title="Д/В требующие восстановления в МКД"
                  subTitle="(по результатам обследования)"
                  titleMaxWidth="100%"
                  :meaning="bar"
                  :theme-mode="themeMode"
                  :bar="true"
                  :class="$style.onLightShadow"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="grid-ventilation__item item-gridVentilation item-gridVentilation_big">
            <template 
              v-if="filter.region === undefined || filter.region === null"
            >
              <div class="item-gridVentilation__head unit" :style="{
                fontSize: '20px'
              }">
              Отключено газоснабжение в МКД по МР и ГО РБ
            </div>
            <div class="item-gridVentilation__body">
              <VueCustomScrollbar :class="$style.wrapper" :settings="scrollbarSettings" class="onLightShadow">
                <VDataTable 
                  :fixed-header="true"
                  hide-default-footer 
                  :headers="table.headers" 
                  :items="table.items"
                  :options.sync="tableOptions"
                  item-key="id"
                  class="custom-table transparent" 
                  :class="$style.gridOutagesTable" 
                  >
                  <template #body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td :style="{fontSize: '17px'}">{{ item.district }}</td>
                        <td :style="{fontSize: '17px'}">{{ item.housesCount }}</td>
                      </tr>
                    </tbody>
                  </template>
                </VDataTable>
              </VueCustomScrollbar>
            
            </div>
            </template>
            <div
            v-else
            class="grid-outages__item grid-outages__item_map"
              :class="$style.gridOutagesMap">
              <div class="unit flex-grow-1">
                <OverhaulMap :theme-mode="themeMode" :mapData="mapData" :mapCenter="mapCenter"/>
              </div>
            </div>
        </div>
        <!-- <div class="grid-ventilation__item item-gridVentilation">
          <div class="item-gridVentilation__head unit"
          :style="{
                fontSize: '20px'
              }"
          >
            Стоимость планово - восстановительных работ по результатам обследования: <br> {{inspectionResult.cost}}&nbsp;руб., {{inspectionResult.housesCount}}&nbsp;МКД
          </div>
        </div> -->
      </div>
    </template>

  </div>
</template>

<script>
import { VCol, VRow } from "vuetify/lib";
import OverhaulMap from "@/components/Overhaul/OverhaulMap";
import OutagesFilters from "@/components/Outages/OutagesFilters";
import VentilationDiagram from "@/components/Ventilation/VentilationDiagram";
import Speedometer from "@/components/Ventilation/Speedometer";
import VentilationBarChart from "@/components/Ventilation/VentilationBarChart";
import { convertIntegerToText } from "@/constants/helpers";
import VueCustomScrollbar from "vue-custom-scrollbar";
import { scrollbarSettings } from "@/constants/scrollbar";
import PlaceholderPage from "@/components/Overhaul/PlaceholderPage";

export default {
  name: 'OverhaulIndex',
  components: {
    VRow,
    VCol,
    OutagesFilters,
    OverhaulMap,
    VentilationDiagram,
    VueCustomScrollbar,
    PlaceholderPage,
    Speedometer,
    VentilationBarChart
  },
  apollo: {
    getAddrObj: {
      query: require('@/graphql/getAddrObj.gql'),
      variables() {
        return {
          level: 3
        }
      },
      result(response) {
        const regions = response.data?.getAddrObj?.nextAddrObjs || [];
        this.regions = [
          {
            fullName: 'Республика Башкортостан',
            objectId: undefined,
          },
          ...regions,
        ];
      }
    },
    getRegionsGasShutdown: {
      query: require('@/graphql/getRegionsGasShutdown.gql'),
      variables() {
        return {
          periodStart: this.filter.period?.[0]?.toString(),
          periodEnd: this.filter.period?.[1]?.toString(),
          orderBy: 'district',
          orderWay: 'asc'
        }
      },
      skip() {
        return this.filter.period?.length < 2;
      },
      result(response) {
        this.table.items = response.data.getRegionsGasShutdown;
      },
    },

    getVentilationDashboard: {
      query: require('@/graphql/getVentilationDashboard.gql'),
      variables() {
        return {
          periodStart: this.filter.period?.[0]?.toString(),
          periodEnd: this.filter.period?.[1]?.toString(),
          districtGarId: this.filter.region
        }
      },
      skip() {
        return this.filter.period?.length < 2;
      },
      result(response) {
        const result = response.data?.getVentilationDashboard;
        this.hardCodeData.totalWithGasEquipment = {
          mkd: result.gasDashboard?.total?.housesCount,
          kv: result.gasDashboard?.total?.apartmentCount
        }
        this.hardCodeData.shutdownTotal = {
          mkd: result.gasDashboard?.shudownedTotal?.housesCount,
          kv: result.gasDashboard?.shudownedTotal?.apartmentCount
        }
        this.hardCodeData.shutdownWithGP = {
          mkd: result.gasDashboard?.shutdownedWithOwens?.housesCount,
          kv: result.gasDashboard?.shutdownedWithOwens?.apartmentCount
        }
        this.hardCodeData.shutdownWithGpGk = {
          mkd: result.gasDashboard?.shutdownedWithOwensAndBoilers?.housesCount,
          kv: result.gasDashboard?.shutdownedWithOwensAndBoilers?.apartmentCount
        }
        this.hardCodeData.shutdownWithGpGv = {
          mkd: result.gasDashboard?.shutdownedWithOwensAndHeaters?.housesCount,
          kv: result.gasDashboard?.shutdownedWithOwensAndHeaters?.apartmentCount
        }
        console.log('getVentilationDashboard', result);
        this.speedometerMaxValue = result.inspectedCVInHouses?.totalCount || 0;
        this.speedometerValue = result.inspectedCVInHouses?.inspectedCount || 0;
        this.speedometer = {
          colors: ["tomato", "gold", "limegreen"],
          // value: result.inspectedCVInHouses?.inspectedCount || 0,
          // maxValue: result.inspectedCVInHouses?.totalCount || 0,
          needleColor: this.$vuetify.theme.dark ? '#ccc' : '#000',
          textColor: this.$vuetify.theme.dark ? '#ccc' : '#000',
        }
        this.updateMapData(result.gasShutdownedHouses ? result.gasShutdownedHouses : this.mapData.items)
        this.mapDataItems = result.gasShutdownedHouses ? result.gasShutdownedHouses : this.mapData.items;
        this.gasEmergencyShutdowns(result.gasEmergencyShutdowns);
        this.barResult = result.repairsCVInHouses;
        this.getRepairsCVInHouses(result.repairsCVInHouses);
        this.getInspectionResult(result);
        this.mapCenter = result.districtGeo ? result.districtGeo.replaceAll(' ', '').split(',') : [54.74728667873139, 55.9947550905636]
      },
    },
  },
  data() {
    return {
      tableOptions: {
        page: 1,
        itemsPerPage: -1,
        pageStart: 1,
        pageStop: 1,
        sortDesc: [ false, false ]
      },
      mapDataItems: [],
      hardCodeData: {
        periodStart: null,
        periodEnd: null,
        totalWithGasEquipment: {
          mkd: 12806,
          kv: 631366,
        },
        shutdownTotal: {
          mkd: 463,
          kv: 8201,
        },
        shutdownWithGP: {
          mkd: 263,
          kv: 4201,
        },
        shutdownWithGpGv: {
          mkd: 163,
          kv: 1201,
        },
        shutdownWithGpGk: {
          mkd: 63,
          kv: 801,
        },
      },
      barResult: null,
      bar: {
        sideTexts: [
          {
            title: 'Всего',
            value: 16000,
            color: '#ccc',
          },
          {
            title: 'Осмечено',
            value: 10000,
            color: '#00a759'
          },
          {
            title: 'Требуют осмечивания',
            value: 6000,
            color: '#d63200'
          },
        ],
        data: {
          series: [{
            name: 'Осмечено',
            data: [0, 10000, 0]
          }, {
            name: 'Требуют осмечивания',
            data: [0, 6000, 0]
          },],
          total: [0, 16000, 0],
          labels: []
        },
        colors: [
          '#00a759', 
          '#d63200'
        ],
      },
      speedometer: {
        colors: ["tomato", "gold", "limegreen"],
        value: 10000,
        maxValue: 12806,
        needleColor: this.$vuetify.theme.dark ? '#ccc' : '#000',
        textColor: this.$vuetify.theme.dark ? '#ccc' : '#000',
      },
      defaultDate: this.setDefaultDate(new Date()),
      speedometerValue: 0,
      speedometerMaxValue: 0,
      regions: [
        {
          fullName: 'Республика Башкортостан',
          objectId: undefined
        }
      ],
      diagramDataOnMoment: {
        sideTexts: [
          {
            title: 'МКД оборудованные только ГП',
            value: 263,
            color: '#00a759'
          },
          {
            title: 'МКД оборудованные ГП и ГК',
            value: 163,
            color: '#0093cb'
          },
          {
            title: 'МКД оборудованные ГП и ГКотл',
            value: 63,
            color: '#fc2626'
          },
        ],
        data: {
          key: 'diagramDataOnMoment',
          series: [263, 163, 63],
          labels: [
            'МКД оборудованные только ГП',
            'МКД оборудованные ГП и ГК',
            'МКД оборудованные ГП и ГКотл'
          ],
        },
        colors: ['#00a759', '#0093cb', '#fc2626'],
      },
      diagramDataOnPeriod: {
        sideTexts: [
          {
            title: 'МКД оборудованные только ГП',
            value: 20,
            color: '#00a759'
          },
          {
            title: 'МКД оборудованные ГП и ГК',
            value: 6,
            color: '#0093cb'
          },
          {
            title: 'МКД оборудованные ГП и ГКотл',
            value: 4,
            color: '#fc2626'
          },
        ],
        data: {
          key: 'diagramDataOnPeriod',
          series: [20, 6, 4],
          labels: [
            'МКД оборудованные только ГП',
            'МКД оборудованные ГП и ГК',
            'МКД оборудованные ГП и ГКотл'
          ]
        },
        colors: ['#00a759', '#0093cb', '#fc2626'],
      },
      filter: {
        period: [],
        region: null,
      },
      mapData: {
        typename: 'VentilationDashboard',
        items: [
          {
            address: "г. Уфа, ул. Бакалинская, д. 21",
            cost: "100000.11",
            geo: "54.718312, 55.989036",
            house: "21",
            houseId:  null,
            inspectionDate: "2022-08-22",
            inspectionResult: "Требуется ремонт",
            locality: "г. Уфа",
            mangmentOrganization: "Уфимская управляющая компания",
            periodEnd: "2022-10-03",
            periodStart: "2022-09-20",
            street: "ул. Бакалинская",
          }
        ]
      },
      table: {
        headers: [
          { text: "Наименование МР/ГО", value: "district", class: "text-uppercase", sortable: false },
          { text: "Отключено МКД", value: "housesCount", class: "text-uppercase", sortable: true },
        ],
        items: [],
      },
      inspectionResult: {
        cost: 0,
        housesCount: 0
      },
      selectedKey: null,
      selectedType: null,
      clearSelectionTwoBool: true,
      mapCenter: [54.74728667873139, 55.9947550905636],
      minDate: '2022-11-05',
      maxDate: '',
      dateIndex: 1
    }
  },
  computed: {
    themeMode() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    pending() {
      return this.$apollo.queries.getVentilationDashboard.loading;
    },
    filterEmpty() {
      return this.filter.period?.length < 2;
    },
    placeholderText() {
      return this.pending ? 'Загружаем данные' : 'Выберите период и регион для отображения данных'
    },
    cardStats() {
      return [
        {
          head: `Всего с газовым оборудованием`,
          left: { caption: 'МКД', value: convertIntegerToText(this.hardCodeData.totalWithGasEquipment.mkd) },
          right: { caption: 'КВ', value: convertIntegerToText(this.hardCodeData.totalWithGasEquipment.kv) },
        },
        {
          head: `Отключено газоснабжение ВСЕГО`,
          left: { caption: 'МКД', value: convertIntegerToText(this.hardCodeData.shutdownTotal.mkd) },
          right: { caption: 'КВ', value: convertIntegerToText(this.hardCodeData.shutdownTotal.kv) },
        },
        {
          head: 'Отключено газоснабжение только с газовыми плитами',
          left: { caption: 'МКД', value: convertIntegerToText(this.hardCodeData.shutdownWithGP.mkd) },
          right: { caption: 'КВ', value: convertIntegerToText(this.hardCodeData.shutdownWithGP.kv) },
        },
        {
          head: 'Отключено газоснабжение с газовыми плитами и газовыми колонками',
          left: { caption: 'МКД', value: convertIntegerToText(this.hardCodeData.shutdownWithGpGv.mkd) },
          right: { caption: 'КВ', value: convertIntegerToText(this.hardCodeData.shutdownWithGpGv.kv) },
        },
        {
          head: 'Отключено газоснабжение с газовыми плитами и газовым котлом',
          left: { caption: 'МКД', value: convertIntegerToText(this.hardCodeData.shutdownWithGpGk.mkd) },
          right: { caption: 'КВ', value: convertIntegerToText(this.hardCodeData.shutdownWithGpGk.kv) },
        },
      ]
    },
    scrollbarSettings: () => scrollbarSettings,
    isFilterCompleted() {
      return this.filter.region && this.filter.period?.length === 2;
    },
    collectionCurrencyChartsData() {
      return {
        titles: [ 'Обследование', 'Ремонт' ],
        series: [{
          name: 'Вент. ед',
          data: [ this.systemsRepairs.inspectedVentilationsCount, this.systemsRepairs.inspectedChimneysCount ],
        }, {
          name: 'Дым. ед',
          data: [ this.systemsRepairs.repairedVentilationCount, this.systemsRepairs.repairedChimneysCount ],
        }],
        colors: [ '#d63200', '#486491' ],
        distributed: false,
        showLegend: true,
      };
    },
    collectionCurrencyChartsDataTwo() {
      const planned = convertIntegerToText(this.systemsRepairCost.totalPlannedCost);
      const factual = convertIntegerToText(this.systemsRepairCost.totalFactualCost);

      return {
        titles: [ 'Текущий', 'Капитальный', 'Реконструкция', 'Новый' ],
        series: [{
          name: `План, ${planned} руб.`,
          data: [
            this.systemsRepairCost.currentPlannedCost,
            this.systemsRepairCost.overhaulPlannedCost,
            this.systemsRepairCost.reconstructionPlannedCost,
            this.systemsRepairCost.newPlannedCost,
          ],
        }, {
          name: `Факт, ${factual} руб.`,
          data: [
            this.systemsRepairCost.currentFactualCost,
            this.systemsRepairCost.overhaulFactualCost,
            this.systemsRepairCost.reconstructionFactualCost,
            this.systemsRepairCost.newFactualCost,
          ],
        }],
        colors: [ '#d63200', '#486491' ],
        distributed: false,
        showLegend: true,
      };
    },
  },
  watch: {
    '$apollo.loading'(pending) {
      this.$emit('change-loading', pending);
    },
    themeMode() {
      this.getRepairsCVInHouses();
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
  methods: {
    setDefaultDate(date) {
      let counter = 0
      if (date.getDay() < 5) {
        counter = 13
      } else {
        counter = 6
      }
      let firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7) - counter).toISOString().substr(0, 10);
      let secondDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + ((7 + 6 - date.getDay()) % 7) - counter+6).toISOString().substr(0, 10);

      return [firstDay, secondDay];
    },
    getRepairsCVInHouses(result = this.barResult) {
      let vsego = result.requiringRestorationCount !== (null||undefined) ? Math.abs(result.requiringRestorationCount) : '0';
      this.bar.data.total = [0, result?.requiringRestorationCount || 0, 0]
      this.bar.sideTexts = [
          {
            title: 'Всего МКД',
            value: vsego,
            color: this.themeMode === 'dark' ? '#fff' :'#000'
          },
          {
            title: 'Осмечено',
            value: Math.abs(result?.estimatedCount),
            color: '#00a759'
          },
          {
            title: 'Требуют осмечивания',
            value: Math.abs(result?.notEstimatedCount),
            color: '#d63200'
          },
        ]
      this.bar.data.series = [ {
        name: 'Осмечено',
        data: [0, Math.abs(result?.estimatedCount) || 0, 0]
      }, {
        name: 'Требуют осмечивания',
        data: [0, Math.abs(result?.notEstimatedCount) || 0, 0]
      },]
    },
    gasEmergencyShutdowns(result) {
      let arrOnMoment = [];
      let arrOnPeriod = [];
      arrOnMoment.push(result?.onMoment?.housesWithOwensCount || 0);
      arrOnMoment.push(result?.onMoment?.housesWithOwensAndHeatersCount || 0);
      arrOnMoment.push(result?.onMoment?.housesWithOwensAndBoilersCount || 0);
      arrOnPeriod.push(result?.onPeriod?.housesWithOwensCount || 0);
      arrOnPeriod.push(result?.onPeriod?.housesWithOwensAndHeatersCount || 0);
      arrOnPeriod.push(result?.onPeriod?.housesWithOwensAndBoilersCount || 0);
      this.diagramDataOnMoment.data.series = arrOnMoment;
      this.diagramDataOnPeriod.data.series = arrOnPeriod;
    },
    dataLabelsFormatter:  (val) => convertIntegerToText(val),
    updateFilter(filter) {
      this.filter.region = filter.region === 'Республика Башкортостан' || filter.region === null ? undefined : filter.region;
      this.filter.period = filter.period?.map(date => {
        const [day, month, year] = date?.split('.');
        return `${year}-${month}-${day}`;
      })
    },
    updateMapData(newData) {
      this.mapData = {
        typename: 'VentilationDashboard',
        items: newData
      };
    },
    newUpdateMap(type, key) {
      const points = this.mapDataItems;
      let pointsNew = [];
      points.forEach(point=>{
        if (type === 0) {
          if (point.hasGasStoven === true && point.hasWaterHeaters === false && point.hasGasBoilers === false) {
            pointsNew.push(point);
          }
        } else if (type === 1) {
          if (point.hasGasStoven === true && point.hasWaterHeaters === true) {
            pointsNew.push(point);
          }
        } else if (type === 2) {
          if (point.hasGasStoven === true && point.hasGasBoilers === true) {
            pointsNew.push(point);
          }
        } else {
          pointsNew.push(point);
        }
      })

      
      if (this.selectedType === type && this.selectedKey === key) {
        this.selectedType = null;
        this.selectedKey = null;
        this.updateMapData(points);
      } else {
        this.updateMapData(pointsNew);
        this.selectedType = type;
        this.selectedKey = key;
      }
      
    },
    clearSelection(event) {
      if (!event.target.closest('.leaflet-container')) {
        let key = this.selectedKey;
        let type = this.selectedType;
        if (!event.target.classList.contains('apexcharts-pie-area')) {
          if (key !== null && type !== null && this.$refs[key]) {
            this.$refs[key].$children[0].$children[0].toggleDataPointSelection(type);
            this.selectedKey = null;
            this.selectedType = null;
            this.updateMapData(this.mapDataItems)
          }
        }
      }
    },
    clearSelectionTwo(type, key) {
        if (this.$refs[key] && this.clearSelectionTwoBool) {
          this.clearSelectionTwoBool = false;
          this.$refs[key].$children[0].$children[0].toggleDataPointSelection(type);
        }
        this.clearSelectionTwoBool = true;
    },
    getInspectionResult(result) {
      if (result.repairsCostCVInHouses.cost !== (null||undefined)) {
        this.inspectionResult.cost = convertIntegerToText(result.repairsCostCVInHouses.cost);
      }
      if (result.repairsCostCVInHouses.housesCount !== (null||undefined)) {
        this.inspectionResult.housesCount = convertIntegerToText(result.repairsCostCVInHouses.housesCount);
      }
    },
    dayClick(e) {
      if (this.dateIndex !== 1) {
        this.dateIndex = 1;
        return
      }
      let date = new Date(e);
      let dayNum = date.getDay();
      let raznitsa = 5 - dayNum;
      e = e.split('-');
      let newStartDate = null;
      let newEndDay = null;
      let b = parseInt(e[e.length - 1]) + raznitsa - 7;
      let c = parseInt(e[e.length - 1]) + raznitsa + 7;
      if (b < 1) {
        if (parseInt(e[1])-1 > 11 && 31+b > 5) {
          newStartDate = `${e[0]}-${parseInt(e[1])-1}-${31+b}`;
        } else {
          newStartDate = '2022-11-05'
        }
      } else {
        b = b < 10 ? '0'+b : b;  
        if (parseInt(e[1])-1 > 11 && 31+b > 5) {
          newStartDate = `${e[0]}-${e[1]}-${b}`;
        } else {
          newStartDate = '2022-11-05'
        }
      }
      if (c>31) {
        c = c-31 < 10 ? '0'+(c-31) : c-31;
        newEndDay = `${e[0]}-${parseInt(e[1])+1}-${c}`;
      } else {
        c = c < 10 ? '0'+c : c;
        newEndDay = `${e[0]}-${e[1]}-${c}`;
      }
      this.maxDate = newEndDay;
      this.minDate = newStartDate;
      this.dateIndex++;
    }
  },
};
</script>

<style lang="scss">
.overhaul-placeholder {
  min-height: 300px;
}

.theme--light.v-application {
  .unit,
  .ps-container {
    border-radius: 16px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)!important;
  }
}

.unit__head {
  padding: 0 10px;
  text-align: center;
}
.unit_tar {
  text-align: right;
}

.top-outages {
  display: grid;
  gap: 24px;
  margin-top: 24px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1360px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &_five {
    display: grid;
    gap: 24px;
    margin-top: 24px;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    @media (max-width: 1360px){
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.grid-ventilation {
  display: grid;
  gap: 24px;
  margin-top: 24px;
  @media (min-width: 992px) {
    grid-template-columns: 66% 34%;
  }

  &__item {}
}

.item-gridVentilation {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &_big {
    @media (min-width: 992px) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 4;
    }
  }

  &__head {
    text-align: center;
    font-size: 24px;
  }

  &__body {
    .item-gridVentilation_big & {
      display: block;
    }
    .theme--light.v-application & .grid-outages__item .flex-grow-1 {
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)!important;
    }
    display: grid;
    gap: 24px;
    max-width: 100%;
    @media (min-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
    flex: 1 1 auto;
  }
}
.leaflet-control [href="https://leafletjs.com"] {
  display: none !important;
}
</style>
<style lang="scss" module>
  .gridOutagesTable {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
  }
  .gridOutagesMap {
    @media (min-width:1360px) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }
.unit {
  padding: 18px 16px;
  border-radius: 16px;
  transition: .3s;
  max-width: 100%;
  &>div {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    justify-content: center;
  }
}
.unit:hover {
  background-color: var(--v-unitHover-base) !important
}
.unit {
    background-color: var(--v-unit-base) !important;
    border-color: var(--v-unit-base) !important;
}

.wrapper {
  max-height: 980px;
}
.unitMh {
  max-height: 63px;
}
</style>
