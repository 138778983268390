<template>
  <v-dialog
    scrollable
    max-width="500px"
    :value="isOpen"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="text-h5">{{ getTitle }}</span>
        <v-btn
          icon
          small
          @click="handleClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          :disabled="pending"
          v-model="valid"
        >
          <v-checkbox
            label="Обследование проведено"
            :value="item.isInspected"
            @change="(value) => handleChangeCheckbox('isInspected', value)"
          />

          <v-menu
            offset-y
            min-width="auto"
            transition="scale-transition"
            :disabled="!item.isInspected"
            :close-on-content-click="false"
            :nudge-right="40"
            v-model="inspectDate"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                readonly
                label="Дата обследования"
                :disabled="!item.isInspected"
                :rules="rules.inspectDate"
                :value="convertDate(item.inspectDate)"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="item.inspectDate"
              @input="inspectDate = false"
            />
          </v-menu>

          <v-select
            item-text="label"
            item-value="value"
            label="Результат обследования"
            :items="isRepairRequired"
            :disabled="!item.isInspected"
            v-model="item.isRepairRequired"
          />

          <v-textarea
            label="Описание требуемого ремонта"
            :disabled="!item.isInspected"
            v-model="item.repairDescription"
          />

          <v-file-input
            show-size
            label="Акт обследования"
            :disabled="!item.isInspected"
            :rules="rules.inspectAct"
            :value="item.inspectAct"
            @change="(file) => handleChangeFile(file, 'inspect_act', 'inspectAct')"
          />

          <v-checkbox
            label="Проведено осмечивание"
            :disabled="!item.isInspected"
            v-model="isEstimated"
          />

          <v-menu
            offset-y
            min-width="auto"
            transition="scale-transition"
            :disabled="!isEstimated"
            :close-on-content-click="false"
            :nudge-right="40"
            v-model="estimateDate"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                readonly
                label="Дата осмечивания"
                :disabled="!isEstimated"
                :rules="rules.estimateDate"
                :value="convertDate(item.estimateDate)"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="item.estimateDate"
              @input="estimateDate = false"
            />
          </v-menu>

          <v-text-field
            type="number"
            label="Сумма затрат по смете, руб."
            :required="item.isEstimated"
            :disabled="!isEstimated"
            :rules="rules.estimatedCost"
            v-model="item.estimatedCost"
          />

          <v-file-input
            show-size
            required
            label="Смета"
            :rules="rules.estimate"
            :disabled="!isEstimated"
            :value="item.estimate"
            @change="(file) => handleChangeFile(file, 'estimate', 'estimate')"
          />

          <v-checkbox
            label="Выполнен ремонт"
            :disabled="!isEstimated"
            v-model="isRepaired"
          />

          <v-text-field
            type="number"
            label="Сумма затрат фактическая, руб."
            :required="item.isRepaired"
            :disabled="!isRepaired"
            :rules="rules.factualCost"
            v-model="item.factualCost"
          />

          <v-menu
            offset-y
            min-width="auto"
            transition="scale-transition"
            :disabled="!isRepaired"
            :close-on-content-click="false"
            :nudge-right="40"
            v-model="repairDate"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                readonly
                label="Дата ремонта"
                :rules="rules.repairDate"
                :disabled="!isRepaired"
                :value="convertDate(item.repairDate)"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="item.repairDate"
              @input="repairDate = false"
            />
          </v-menu>

          <v-file-input
            show-size
            label="Акт КС-2"
            :rules="rules.repairAct"
            :disabled="!isRepaired"
            :value="item.repairAct"
            @change="(file) => handleChangeFile(file, 'repair_act', 'repairAct')"
          />
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="success"
          :loading="pending"
          @click="handleSaveItem"
        >
          {{ editedId ? "Сохранить" : "Создать" }}
        </v-btn>
        <v-btn
          small
          color="error"
          :disabled="pending"
          @click="handleClose"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "ConditionEditModal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    houseId: {
      type: [ Number ],
      default: null,
    },
    editedId: {
      type: [ Number ],
      required: true,
    },
    editedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      pending: false,
      inspectDate: false,
      estimateDate: false,
      repairDate: false,
      isRepairRequired: [
        { label: "Требуется ремонт", value: true },
        { label: "В рабочем состоянии", value: false },
      ],
      item: {
        isInspected: false,
        inspectDate: "",
        isRepairRequired: false,
        repairDescription: "",
        inspectAct: null,
        isEstimated: false,
        estimateDate: "",
        estimatedCost: null,
        estimate: null,
        isRepaired: false,
        repairDate: "",
        factualCost: null,
        repairAct: null,
      },
      rules: {
        inspectDate: [
          (value) => !this.item.isInspected || Boolean(value) || "Поле обязательно к заполнению",
          (value) => !this.item.estimateDate || this.checkDates(this.item.estimateDate, value) || "Дата обследования не может быть больше даты осмечивания",
        ],
        inspectAct: [
          (value) => !this.item.isInspected || Boolean(value) || "Поле обязательно к заполнению",
        ],
        estimateDate: [
          (value) => !this.isEstimated || Boolean(value) || "Поле обязательно к заполнению",
          (value) => !value || this.checkDates(value, this.item.inspectDate) || "Дата осмечивания не может быть меньше даты обследования",
        ],
        estimatedCost: [
          (value) => !this.isEstimated || Boolean(value) || "Поле обязательно к заполнению",
          (value) => !value || /^\d+(?:\.\d{1,2})?$/.test(value) || "Максимум 2 цифры после запятой",
        ],
        estimate: [
          (value) => !this.isEstimated || Boolean(value) || "Поле обязательно к заполнению",
        ],
        factualCost: [
          (value) => !this.isRepaired || Boolean(value) || "Поле обязательно к заполнению",
          (value) => !value || /^\d+(?:\.\d{1,2})?$/.test(value) || "Максимум 2 цифры после запятой",
        ],
        repairDate: [
          (value) => !this.isRepaired || Boolean(value) || "Поле обязательно к заполнению",
          (value) => !value || this.checkDates(value, this.item.estimateDate) || "Дата ремонта не может быть меньше даты осмечивания",
        ],
        repairAct: [
          (value) => !this.isRepaired || Boolean(value) || "Поле обязательно к заполнению",
        ],
      },
    };
  },
  mounted() {
    this.item = { ...this.editedItem };
  },
  watch: {
    editedItem(item) {
      this.item = item;
      this.$refs.form?.resetValidation?.();
    }
  },
  computed: {
    getTitle() {
      return !this.editedId ? "Создание записи" : "Редактирование записи";
    },
    isEstimated: {
      get() {
        return this.item.isEstimated && this.item.isInspected;
      },
      set(value) {
        this.item.isEstimated = value;
        this.$refs.form.resetValidation();
      }
    },
    isRepaired: {
      get() {
        return this.item.isRepaired && this.item.isEstimated;
      },
      set(value) {
        this.item.isRepaired = value;
        this.$refs.form.resetValidation();
      }
    },
  },
  methods: {
    convertDate(date = "") {
      return moment(date).isValid() ? moment(date).format("DD.MM.YYYY") : "";
    },
    checkDates(date1, date2) {
      const currentDate1 = date1.split(".").reverse().join("-");
      const currentDate2 = date2.split(".").reverse().join("-");
      return new Date(currentDate1) >= new Date(currentDate2);
    },
    handleChangeCheckbox(field, value) {
      this.item[field] = value;
      this.$refs.form.resetValidation();
    },
    handleChangeFile(file, field, type) {
      if (!file) {
        return;
      }

      this.pending = true;

      const formData = new FormData();
      formData.append("file", file);

      fetch(`https://sa.yurtarb.ru/upload?file_type=${ field }`, {
        method: "POST",
        body: formData,
      })
        .then(response => response.json())
        .then(result => {
          this.item[type] = {
            guid: result?.guid || "",
            id: result?.id || "",
            name: result?.name || "",
            size: result?.size || "",
          };
        })
        .catch((error) => {
          console.error("error", error);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    handleClose(successful = false) {
      if (!this.pending) {
        this.$emit("close", successful);
      }
    },
    async handleCreateItem() {
      const successful = await this.$apollo.mutate({
        mutation: require("@/graphql/createVentilationRepair.gql"),
        skip() {
          return !this.valid;
        },
        variables: {
          houseId: this.houseId,
          ventilationRepairData: {
            ...this.item.isInspected ? {
              isInspected: this.item.isInspected,
              inspectDate: this.item.inspectDate,
              isRepairRequired: this.item.isRepairRequired,
              repairDescription: this.item.repairDescription,
              inspectActFileId: Number(this.item.inspectAct?.id) || 0,
            } : {},
            ...this.item.isEstimated ? {
              isEstimated: this.item.isEstimated,
              estimateDate: this.item.estimateDate,
              estimatedCost: Number(this.item.estimatedCost),
              estimateFileId: Number(this.item.estimate?.id) || 0,
            } : {},
            ...this.item.isRepaired ? {
              isRepaired: this.item.isRepaired,
              repairDate: this.item.repairDate,
              factualCost: Number(this.item.factualCost),
              repairActFileId: Number(this.item.repairAct?.id) || 0,
            } : {},
          },
        },
      })
        .then((response) => Boolean(response?.data?.createVentilationRepair?.id))
        .catch(() => false);

      return successful;
    },
    async handleUpdateItem() {
      const successful = await this.$apollo.mutate({
        mutation: require("@/graphql/updateVentilationRepair.gql"),
        skip() {
          return !this.valid;
        },
        variables: {
          ventilationRepairId: this.editedId,
          ventilationRepairData: {
            ...this.item.isInspected ? {
              isInspected: this.item.isInspected,
              inspectDate: this.item.inspectDate,
              isRepairRequired: this.item.isRepairRequired,
              repairDescription: this.item.repairDescription,
              inspectActFileId: Number(this.item.inspectAct?.id) || 0,
            } : {},
            ...this.item.isEstimated ? {
              isEstimated: this.item.isEstimated,
              estimateDate: this.item.estimateDate,
              estimatedCost: Number(this.item.estimatedCost),
              estimateFileId: Number(this.item.estimate?.id) || 0,
            } : {},
            ...this.item.isRepaired ? {
              isRepaired: this.item.isRepaired,
              repairDate: this.item.repairDate,
              factualCost: Number(this.item.factualCost),
              repairActFileId: Number(this.item.repairAct?.id) || 0,
            } : {},
          },
        },
      })
        .then((response) => Boolean(response?.data?.updateVentilationRepair?.id))
        .catch(() => false);

      return successful;
    },
    async handleSaveItem() {
      const isValid = this.$refs.form.validate();
      let successful = false;

      if (!isValid) {
        return;
      }

      this.pending = true;

      if (this.editedId) {
        successful = await this.handleUpdateItem();
      } else {
        successful = await this.handleCreateItem();
      }

      this.pending = false;

      if (successful) {
        this.handleClose(successful);
      }
    },
  },
};
</script>
